// Root
//
// Add variables to the root element

:root {
    @each $bp, $value in $grid-breakpoints {
        --breakpoint-#{$bp}: #{$value};
    }

    --paragraphs-gutter-x: #{$paragraphs-cols-gutter-x};
    --paragraphs-gutter-y: #{$paragraphs-cols-gutter-y};
    --sticky-header-height: #{$sticky-header-height};
    --sticky-header-scrolling-height: #{$sticky-header-scrolling-height};
    --primary-nav-breakpoint: #{$primary-nav-breakpoint};
    // Use `inspect` for lists so that quoted items keep the quotes.
    // See https://github.com/sass/sass/issues/2383#issuecomment-336349172
    --font-base: #{inspect($font-base)};
    --font-second: #{inspect($font-second)};
}
