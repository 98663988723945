.breadcrumb {
    margin-bottom: 20px;
    //font-size: px-to-rem(15px);

    @include media-breakpoint-up(md) {
        margin-bottom: 40px;
    }
}

.breadcrumb-item + .breadcrumb-item::before {
    @include sprite("sprite-client-general-chevron-right", (
        color: $secondary
    ));

    display: inline-block;
    float: none;
    width: 5px;
    height: 9px;
    padding-right: $breadcrumb-item-padding-x / 2;
}
