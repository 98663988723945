.messages-list {
    margin-top: $messages-list-margin-top;
    margin-bottom: $messages-list-margin-bottom;
    list-style: none;
}

.messages {
    min-height: 68px;
    padding: $messages-messages-padding-y $messages-messages-padding-x;
    margin-top: $messages-messages-margin-top;
    margin-bottom: $messages-messages-margin-top;
    background-color: $white;
    border: $messages-messages-border-width solid $messages-messages-border-color;
    border-left-width: $messages-messages-border-left-width;

    * {
        color: inherit;
    }

    &.messages-list__item {
        background-image: none;
    }

    pre {
        margin: 0;
    }
}

.messages__list {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    list-style: none;
}

.messages:not(.hidden) ~ .messages {
    margin-top: 18px;
}

.messages__item + .messages__item {
    margin-top: 9px;
}

.messages__container {
    display: flex;
}

.messages__header {
    flex-shrink: 0;
    padding-right: $spacer * .5;
}

.messages__content {
    flex: 1;
    padding-top: 3px;
    overflow: auto; // Ensure large code blocks can be scrolled to.
}

.messages__button {
    flex-shrink: 0;
    padding-top: 3px;
    margin-left: $spacer * .5;
}

.messages__close {
    position: relative;
    width: 25px;
    height: 25px;
    padding-top: 0;
    padding-bottom: 0;
    vertical-align: top;
    cursor: pointer;
    background: none;
    border: 0;

    &::before,
    &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 33px;
        height: 2px;
        content: "";
        background-color: $link-color;
    }

    &::before {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:hover {
        &::before,
        &::after {
            background-color: $link-hover-color;
        }
    }
}


.messages__icon {
    width: $messages-messages-icon-width;
    height: $messages-messages-icon-height;
    margin-top: 5px;

    svg {
        max-width: 100%;
        max-height: 100%;
        vertical-align: top;
    }
}

.messages--error {
    border-left-color: $danger;

    .messages__icon {
        color: $danger;
    }
}

.messages--warning {
    border-left-color: $warning;

    .messages__icon {
        color: $warning;
    }
}

.messages--status  {
    border-left-color: $success;

    .messages__icon {
        color: $success;
    }
}

.messages--info  {
    border-left-color: $info;

    .messages__icon {
        color: $info;
    }
}
