// Typography
@include media-breakpoint-down(md) {
    body {
        font-size: $font-size-base-xs;
    }
}

// placeholder copied from bootstrap
%heading {
    margin-top: 0; // 1
    margin-bottom: $headings-margin-bottom;
    font-family: $headings-font-family;
    font-style: $headings-font-style;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
}

h1 {
    @extend %heading;
    margin-bottom: 15px;
    font-size: $h1-font-size-xs;
    font-weight: $font-weight-semibold;
    line-height: $h1-line-height;
    color: $secondary;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
        @include font-size($h1-font-size);
        margin-bottom: 20px;
    }

    // stylelint-disable-next-line selector-no-qualifying-type
    &.stage__headline {
        margin-bottom: 0 !important; // stylelint-disable-line declaration-no-important
    }

    + h2 {
        margin-top: .5rem;
        margin-bottom: ($h2-font-size-xs * .5) + px-to-rem(25px);
    }

    @at-root .theme-secondary & {
        color: $white;
    }
}
.h1 {
    @extend h1;
}

h2 {
    @extend %heading;
    font-size: $h2-font-size-xs;
    font-weight: $font-weight-normal;
    line-height: $h2-line-height;

    @include media-breakpoint-up(md) {
        @include font-size($h2-font-size);

        &.stage__subheadline { // stylelint-disable-line selector-no-qualifying-type
            @include font-size($h2-subheadline-font-size);
        }
    }

    + .stage__description,
    + h3 {
        margin-top: px-to-rem(-8px);
        margin-bottom: ($h3-font-size-xs * .5) + px-to-rem(30px);
    }
}
// style subheadline if not type of headline (p and span)
// is for headline-paragraph
.paragraph--headline,
// is for paragraphs with headlines
.paragraph__headline {
    h2 {
        + span {
            display: block;
        }

        + p,
        + span {
            margin-top: px-to-rem(-8px);
            margin-bottom: 1rem;
        }
    }
}
.h2 {
    @extend h2;
}

h3 {
    @extend %heading;
    font-size: $h3-font-size-xs;
    font-weight: $font-weight-medium;
    line-height: $h3-line-height;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
        font-size: $h3-font-size;
    }

    + span {
        display: block;
    }

    + p,
    + span {
        margin-top: px-to-rem(-8px);
        margin-bottom: 1rem;
    }
}
.h3 {
    @extend h3;
}

h4 {
    @extend %heading;
    font-size: $h4-font-size-xs;
    font-weight: $font-weight-medium;
    line-height: $h4-line-height;

    @include media-breakpoint-up(md) {
        @include font-size($h4-font-size);
        font-weight: $font-weight-normal;
    }
}
.h4 {
    @extend h4;
}

h5 {
    @extend %heading;
    font-size: $h5-font-size-xs;
    font-weight: $font-weight-normal;
    line-height: $h5-line-height;

    @include media-breakpoint-up(md) {
        @include font-size($h5-font-size);
    }
}
.h5 {
    @extend h5;
}

h6 {
    @extend %heading;
    font-size: $h6-font-size-xs;
    font-weight: $font-weight-normal;
    line-height: $h6-line-height;

    @include media-breakpoint-up(md) {
        @include font-size($h6-font-size);
    }
}
.h6 {
    @extend h6;
}

.main-content {
    ul:not(.nav):not(.list-unstyled):not(.pagination):not(.dropdown-menu):not(.list-icons):not(.history__list) {
        li::before {
            position: relative;
            top: -2px;
            left: -12px; // whitespace
            display: inline-block;
            width: 7px;
            height: 7px;
            margin-left: -7px; // same as width
            content: "";
            background-color: $secondary;
        }
    }

    ul:not(.nav):not(.list-unstyled):not(.pagination):not(.dropdown-menu):not(.history__list) {
        list-style: none;

        a {
            display: inline-block;
            letter-spacing: .8px;
        }

        li {
            padding-left: 20px;
            letter-spacing: .8px;

            + li { // stylelint-disable-line selector-max-type
                margin-top: 5px;

                @include media-breakpoint-up(md) {
                    margin-top: 10px;
                }
            }
        }

        + * {
            margin-top: $paragraph-margin-bottom;
        }
    }

    // fix ol
    ol:not(.breadcrumb) {
        padding-left: px-to-rem(20px);

        li {
            letter-spacing: .8px;
        }

        + * {
            margin-top: $paragraph-margin-bottom;
        }
    }

    // list styling
    .paragraph {
        ul,
        ol {
            margin-left: 1rem;

            // stylelint-disable-next-line selector-max-compound-selectors
            li + li { // stylelint-disable-line selector-max-type
                // stylelint-disable-next-line declaration-no-important
                margin-top: $grid-gutter-width / 4 !important;
            }
        }
    }
}

p,
a,
span,
label {
    letter-spacing: .8px;
}

.subline {
    font-size: $h3-font-size-xs;
    line-height: 1.25;
    color: $headings-color;

    @include media-breakpoint-up(md) {
        font-size: $h3-font-size;
    }

    + h3 {
        font-weight: 700;
        text-transform: none;
    }
}

.list-icons {
    position: relative;
    list-style: none;

    li {
        margin-left: 17px;
    }

    .sprite {
        position: absolute;
        left: 0;
        width: 22px;
        height: 22px;
        margin-top: 4px;
    }
}

.working-hours {
    li {
        display: flex;
        flex-wrap: nowrap;

        > div {
            display: flex;
            flex: 1 1 100%;

            &:last-child {
                padding-left: $grid-gutter-width / 2;
                font-weight: $font-weight-bold;
            }
        }
    }

    @include media-breakpoint-only(lg) {
        li {
            padding-left: 0 !important; // stylelint-disable-line declaration-no-important
            margin-left: 0;
        }

        .sprite {
            position: relative;
            margin: 0 0 10px;
        }
    }
}

// style hover of download links
a {
    @include transition();
}

a[data-mq-download-manager-item]:not([href]):not([class]) { // stylelint-disable-line selector-no-qualifying-type
    color: $link-color;

    &:hover,
    &:focus {
        color: $link-hover-color;
    }
}

// FIX for RTE content
[class*="paragraph--text"] {
    .paragraph__content-container {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            &:not(:first-child) {
                margin-top: 1.75em;
                margin-bottom: $headings-margin-bottom;
            }
        }
    }
}

.text-notransform {
    text-transform: none !important; // stylelint-disable-line declaration-no-important
}

.agb-text {
    font-size: .7rem;
}
