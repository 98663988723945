.menu-collapse {
    &:not(.is-active) {
        display: none;
    }
}

.menu-collapsing {
    height: 0;
    overflow: hidden;
    @include transition($transition-collapse);
}
