.tabs {
    @include media-breakpoint-down(md) {
        .nav-tabs {
            display: none;
        }
    }

    @include media-breakpoint-up(md) {
        &.show--mobile .nav-tabs,
        &:not(.show--mobile) .nav-tabs-mobile,
        &:not(.show--mobile) .dropdown {
            display: none;
        }
    }
}

.nav-tabs {
    flex-wrap: nowrap;

    @include media-breakpoint-down(md) {
        display: none;
    }

    .nav-item {
        font-size: $h5-font-size;
        line-height: 1;

        @include media-breakpoint-down(md) {
            text-transform: none;
        }

        @include media-breakpoint-up(md) {
            + .nav-item {
                margin-left: 40px;
            }
        }
    }

    .nav-link {
        position: relative;
        padding: 0 0 12px;
        margin-bottom: 0;
        white-space: nowrap;

        &::before {
            @include transition();
            $h: 3px;
            position: absolute;
            right: 0;
            bottom: -$h;
            left: 0;
            width: 0;
            height: $h;
            margin: 0 auto;
            content: "";
            background-color: $nav-tabs-border-color;
        }

        &.active::before {
            width: 100%;
        }

        @include media-breakpoint-up(md) {
            &:hover,
            &:focus,
            &.active {
                font-weight: $font-weight-bold;
                color: currentColor;
            }
        }
    }
}

.tab-content {
    padding-top: $paragraph-margin-bottom;

    @include media-breakpoint-up(sm) {
        $n: 2;
        padding-top: $paragraph-margin-bottom * $n;
    }

    @include media-breakpoint-up(md) {
        $n: 3;
        padding-top: $paragraph-margin-bottom * $n;
    }

    @include media-breakpoint-up(lg) {
        $n: 4;
        padding-top: $paragraph-margin-bottom * $n;
    }
}
