/**
 * Content Teaser -- embedded
 */
.paragraph--layout-embedded {
    .card {
        .media {
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .card-body {
            padding-right: 30px;
            padding-left: 30px;
        }
    }
}
