.hekuma-squares {
    display: flex;
    justify-content: flex-end;
    gap: 5px;

    .square {
        width: 40px;
        aspect-ratio: 1;

        &-1 {
            background-color: $hekuma-square-1;
        }

        &-2 {
            background-color: $hekuma-square-2;
        }

        &-3 {
            background-color: $hekuma-square-3;
        }

        &-4 {
            background-color: $hekuma-square-4;
        }

        &-5 {
            background-color: $hekuma-square-5;
        }

        &-6 {
            background-color: $hekuma-square-6;
        }
    }
}
