.brand-format {
    font-family: $font-name-lato;
    font-weight: $font-weight-bold;
    color: $hekuma-grey;
    text-transform: none;
    white-space: nowrap;

    i {
        font-weight: $font-weight-normal;
    }

    &.brand-format-green {
        i {
            color: $green;
        }
    }

    &.brand-format-blue {
        i {
            color: $blue;
        }
    }
}
