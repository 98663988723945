.paragraph--filterable-jobs {
    .node--type-job-highlighted {
        .card {
            border: 4px solid $secondary;

            .card-title {
                font-weight: $font-weight-bold;
                color: $primary-dark;
            }
        }
    }
}
