// adding equal height on slider items
.swiper-slide {
    height: auto;
}

/**
 * Scrollbar
 */
.swiper-scrollbar,
.swiper-scrollbar-drag {
    @include border-radius(0, 0);
    height: 6px;
    background-color: #cfcfcf;
}

.swiper-scrollbar {
    width: 300px;
    margin: 30px auto 0;

    @include media-breakpoint-up(md) {
        margin-top: 50px;
    }

    @at-root .theme-primary & {
        background-color: $lighter-grey;
    }
}

.swiper-scrollbar-drag {
    cursor: pointer;
    background-color: $black;
}

/**
 * Navigation Buttons (prev & next)
 */
.swiper-button-prev,
.swiper-button-next {
    @include transition();
    padding: 28px 20px;
    margin-top: -28px;
    background-color: $swiper-theme-bg;

    &:hover,
    &:focus {
        background-color: $swiper-theme-bg-hover;
    }

    @include media-breakpoint-up(md) {
        padding: 38px 30px;
        margin-top: -38px;
    }
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    &:hover,
    &:focus {
        background-image: swiper-svg-uri(slider-arrow($slider-arrow-prev, $slider-arrow-width, $slider-arrow-height, $swiper-theme-color-hover));
    }
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    &:hover,
    &:focus {
        background-image: swiper-svg-uri(slider-arrow($slider-arrow-next, $slider-arrow-width, $slider-arrow-height, $swiper-theme-color-hover));
    }
}


/**
 * Swiper Thumbs
 */
.slider-layout-thumb + .slider-layout-nav {
    padding-right: 50px;
    padding-left: 50px;
    margin-top: $grid-gutter-width;
}

.swiper-container-thumbs {
    // better to remove media description on rendering
    .figure-caption {
        display: none;
    }

    .swiper-slide {
        // &::after is blocking to click the play button of videos
        &::before,
        &::after {
            position: absolute;
            top: 0;
            z-index: 1;
            content: "";
        }

        &::before {
            @include transition();
            width: 0;
            height: 10px;
            background-color: $secondary;
        }

        &::after {
            right: 0;
            bottom: 0;
            left: 0;
        }

        &-thumb-active,
        &:hover {
            &::before {
                width: 100%;
            }
        }
    }

    ~ .swiper-button-prev,
    ~ .swiper-button-next {
        top: 0;
        width: 34px;
        height: 100%;
        padding: 0;
        margin: 0;
    }

    ~ .swiper-button-prev,
    &.swiper-container-rtl ~ .swiper-button-next {
        left: 0;
    }

    ~ .swiper-button-next,
    &.swiper-container-rtl ~ .swiper-button-prev {
        right: 0;
    }
}

/**
 * Coverflow
 */
.swiper-container-coverflow {
    .swiper-slide:not(.swiper-slide-active) {
        opacity: .3;
    }
}
