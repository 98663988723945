// Header search
// --------------------------

.mq-search-block-form {
    position: static;

    form {
        display: flex;
    }

    .form-type-search {
        width: 100%;
    }
}

.header-search {
    @include transition(all .35s ease-in-out);

    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 100%;
    height: 0;
    padding: 0;
    overflow: hidden;
    visibility: hidden;
    background-color: $gray-300;
    border-bottom: 10px solid $secondary;

    &.is-active {
        height: px-to-rem(60px) + ($grid-gutter-width * 2);
        padding: $grid-gutter-width 0;
        visibility: visible;
    }

    .form-item {
        margin-top: 2px;
    }

    .form-control:focus {
        border-color: $secondary;
    }
}

.search-button {
    @include transition();
    align-items: center;
    color: $link-color;
    background: transparent;
    border: 0;

    &:hover {
        color: $link-hover-color;
    }

    svg {
        height: px-to-rem(14px);
    }
}

@include media-breakpoint-down(md) {
    .search-form__submit {
        margin-top: 2px;
    }
}
