.pager {
    margin-top: 25px;

    @include media-breakpoint-up(md) {
        margin-top: 50px;
    }

    .btn {
        .sprite {
            align-self: center;
            width: 13px;
            height: 8px;
        }
    }

    .pagination {
        .sprite {
            width: 8px;
            height: 13px;
            margin-top: -2px;
        }
    }
}
