/**
 * only for node-type-article // news
 */
.node--type-statement {
    @include media-breakpoint-up(lg) {
        .media {
            position: relative;
            height: 100%;
            overflow: hidden;
            aspect-ratio: 1;

            img {
                position: absolute;
                left: 0;
                height: 100%;
                object-fit: cover;

            }
        }
    }

    .card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: $grid-gutter-width / 2;
        margin-bottom: 0;
        line-height: 1.5;

        @include media-breakpoint-up(sm) {
            padding: px-to-rem(45px) px-to-rem(50px);
        }

        @include media-breakpoint-up(xl) {
            padding-right: 150px;
        }
    }

    .blockquote {
        font-size: $font-size-base;
        font-style: italic;
        font-weight: $font-weight-base;
    }

    .blockquote-footer {
        margin: 0;
        color: currentColor;

        &::before {
            content: none;
        }
    }

    &:hover,
    &:focus {
        .media img { // stylelint-disable-line selector-max-compound-selectors
            transform: none;
        }
    }

    //+ .node--type-statement {
    //    margin-top: map-get($spacers, 2);
    //
    //    @include media-breakpoint-up(md) {
    //        margin-top: map-get($spacers, 4);
    //    }
    //}

    @include media-breakpoint-down(sm) {
        .blockquote-footer {
            font-size: 15px;
        }
    }
}
