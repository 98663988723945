/**
 * only for node-type-page
 */
%node--type-page {
    .card {
        .media {
            overflow: hidden;
        }

        &:hover,
        &:focus {
            .card-body::before {
                width: 100%;
            }
        }
    }

    .card:not(.card--clean) .card-body::before {
        content: "";
    }

    .card-body {
        position: relative;

        &::before {
            @include transition();

            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            z-index: 1;
            display: block;
            width: 0;
            height: 3px;
            background-color: $secondary;
        }
    }

    .card-img-top + .card-body::before {
        top: -3px;
    }


    /**
     *  Style for view-mode
     */
    &.node--view-mode-embedded {
        @include media-breakpoint-down(sm) {
            .card-body::before {
                top: -3px;
            }
        }

        @include media-breakpoint-up(sm) {
            .card-body {
                height: 100%;
            }
        }
    }
}

%node--type-page--hide {
    @include media-breakpoint-down(sm) {
        .card-text {
            display: none;
        }
    }
}

.paragraph .node--type-page {
    @extend %node--type-page;
    @extend %node--type-page--hide;
}

// stylelint-disable selector-max-class
//.paragraph.paragraph--layout-grid.teaser-style--default .node--type-page {
//    .card {
//        color: $body-color;
//    }
//}
// stylelint-enable selector-max-class
