

.teaser {
    position: relative;
    height: 100%;
    padding: $teaser-padding-y $teaser-padding-x;
    background-color: $teaser-bg-color;
    border: $teaser-border-width solid $teaser-border-color;

    @include border-radius($teaser-border-radius, 0);
    @include box-shadow($teaser-box-shadow);

    &__headline {
        display: block;
        margin-bottom: $teaser-headline-margin-bottom;
        font-family: $teaser-headline-font-family;
        font-size: $teaser-headline-font-size;
        font-weight: $teaser-headline-font-weight;
        line-height: $teaser-headline-line-height;
        color: $teaser-headline-color;
    }

    &__subheadline {
        display: block;
        margin-bottom: $teaser-subheadline-margin-bottom;
        font-family: $teaser-subheadline-font-family;
        font-size: $teaser-subheadline-font-size;
        font-weight: $teaser-subheadline-font-weight;
        line-height: $teaser-subheadline-line-height;
        color: $teaser-subheadline-color;
    }

    &__description {
        margin-bottom: $teaser-description-margin-bottom;
        font-family: $teaser-description-font-family;
        font-weight: $teaser-description-font-weight;
        line-height: $teaser-description-line-height;
        color: $teaser-description-color;
    }
}
