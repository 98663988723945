/**
 * only style for node--type-event
 */
.taxonomy-term--vocabulary-career-level {
    $card-img-overlay-padding-xs: px-to-rem(8px);

    .card {
        @extend %card-img-cover;

        &:hover,
        &:focus {
            .card-img-overlay {
                color: $secondary;
            }

            .media img {
                transform: none;
            }
        }
    }

    .card-img .inner,
    .card-img-overlay {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
    }

    .card-img {
        @include transition();
        height: 100%;
        min-height: 115px;
        padding: $card-img-overlay-padding-xs;
        color: $black;
        text-align: left;
        background-color: $secondary;

        @include media-breakpoint-down(md) {
            .inner {
                display: none;
            }
        }

        @include media-breakpoint-up(md) {
            min-height: 250px;
            padding: $card-img-overlay-padding;
        }
    }

    .card-title {
        font-weight: $font-weight-black;
        text-transform: uppercase;

        @include media-breakpoint-down(md) {
            font-size: $font-size-base-xs;
        }
    }

    .card-img-overlay {
        color: rgba($secondary, .8);

        @include media-breakpoint-down(md) {
            padding: $card-img-overlay-padding-xs;
        }

        .card-title {
            margin-bottom: 0;
        }
    }

    .card-img-overlay--bg {
        @include transition();
        padding: 0;
    }

    // special style for different view-mode
    @include media-breakpoint-down(md) {
        &.swiper-slide,
        &.taxonomy-term--view-mode-embedded {
            .card-img {
                min-height: 150px;
            }
        }
    }

    /**
     * this is a css solution
     * to detect devices that have a primary input mechanism to can hover over elements
     * or you use modernizer solution and move the css code from @media (hover: hover) to body.no-touchevents
     */
    @media (hover: hover) and (pointer: fine) {
        @include media-breakpoint-up(md) {
            .paragraph--layout-grid &,
            .paragraph--layout-embedded &,
            .paragraph--layout-slider &,
            .paragraph--layout-masonry & {
                .card {
                    // stylelint-disable selector-max-class, selector-max-compound-selectors
                    .stretched-link {
                        display: none;
                    }

                    &:hover,
                    &:focus {
                        .card-img-overlay {
                            visibility: hidden;
                            opacity: 0;
                        }
                    }
                    // stylelint-enable selector-max-class, selector-max-compound-selectors
                }
            }
        }
    }
}

// if the css solution not the best solution
//body.no-touchevents {}
