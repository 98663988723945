@each $color, $value in $customer-theme-colors {
    .theme-#{$color} {
        color: customer-theme-color($color, "bodytext");
        background-color: customer-theme-color($color, "background");

        h2,
        h3,
        h4,
        h5,
        h6 {
            &:not(p):not(.card-title) {
                color: customer-theme-color($color, "headline");
            }
        }

        a:not(.btn),
        a[data-mq-download-manager-item]:not([href]):not([class]) {
            color: customer-theme-color($color, "link");

            &:hover,
            &:focus {
                color: customer-theme-color($color, "link-hover");
            }
        }

        .accordion-button {
            $accordion-color-theming: customer-theme-color($color, "bodytext");
            $accordion-button-icon-theming: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-color-theming}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
            color: $accordion-color-theming;
            border-color: $accordion-color-theming;

            &:not(.collapsed)::after,
            &::after {
                background-image: escape-svg($accordion-button-icon-theming);
            }
        }

        $ajax-loader: customer-theme-color($color, "ajax-loader");
        @if $ajax-loader {
            .ajax-progress-throbber .throbber,
            .ajax-progress-fullscreen::before {
                --ajax-throbber-border-color: #{$ajax-loader};
                border-color: $ajax-loader;
                border-right-color: transparent;
            }
        }

        $t-card: customer-theme-color($color, "card");
        @if $t-card {
            .card {
                color: nth($t-card, 2);

                //.btn-outline-white {
                //    @include button-outline-variant(nth($t-card, 2), nth($t-card, 1));
                //}
            }

            .card-img.h-100,
            .card-img-top,
            .card-img-overlay--bg,
            .card-body {
                background-color: nth($t-card, 1);
            }

            @if $color == "secondary" {
                &.paragraph .node--type-page .card-body::before {
                    background-color: $primary-dark;
                }
            }
        }

        $keyfact: customer-theme-color($color, "keyfact");
        @if $keyfact {
            .keyfacts--content {
                color: nth($keyfact, 1);
            }

            .keyfacts--desc {
                color: nth($keyfact, 2);
            }
        }

        $highlight-box: customer-theme-color($color, "highlight-box");
        @if $highlight-box {
            &.paragraph--layout-highlighted {
                &,
                &--left .row > div:first-child,
                &--right .row > div:last-child {
                    .paragraph__content-container {
                        color: customer-theme-color($highlight-box, "bodytext");
                        background-color: customer-theme-color($highlight-box, "background");

                        @if $color == "secondary" {
                            border-color: $primary-dark;
                        }
                    }
                }
            }
            &.paragraph--text-with-media.paragraph--layout-highlighted--right {
                // stylelint-disable selector-max-class
                .row > div .paragraph__content-container {
                    background-color: customer-theme-color($highlight-box, "background");
                }
                // stylelint-enable selector-max-class
            }
        }

        $slider-nav-border-color: customer-theme-color($color, "slider-nav-border-color");
        @if $slider-nav-border-color {
            .swiper-container-thumbs .swiper-slide::before {
                background-color: $slider-nav-border-color;
            }
        }
    }

    .teaser-style--#{$color} {
        .card-body {
            color: customer-theme-color($color, "bodytext");
            background-color: customer-theme-color($color, "background");

            @if $color == "secondary" {
                &::before {
                    background-color: $light-grey;
                }
            }
        }
    }
}
