.kununu-item {
    @include font-size($h4-font-size);

    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    padding: $grid-gutter-width / 2;
    line-height: $h4-line-height;
    text-align: center;

    > div:first-child:not(:last-child) {
        margin-top: $grid-gutter-width / 2;
    }

    @include media-breakpoint-up(md) {
        padding: $grid-gutter-width;

        > div:first-child:not(:last-child) {
            margin-top: $grid-gutter-width;
        }
    }
}
