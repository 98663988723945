.mq-download-manager {
    $simplebar-offset: 10px;

    .offcanvas {
        height: auto;
        max-height: 80vh;

        &[aria-modal="true"] .offcanvas-toggle {
            .btn {
                box-shadow: none;
            }

            .sprite {
                transform: rotate(180deg);
            }
        }
    }

    .offcanvas-toggle {
        height: 0;
        text-align: center;
        visibility: initial;

        .btn {
            margin-top: 0;
            box-shadow: $box-shadow;
            transform: translateY(-100%);
        }

        .sprite {
            @include transition(transform $offcanvas-transition-duration ease-in-out);
            max-width: 16px;
            max-height: 16px;
        }
    }

    .offcanvas-body--with-simplebar {
        max-height: 66vh;
        padding: 0;
        margin: ($grid-gutter-width / 2) $simplebar-offset ($grid-gutter-width / 2) 0;

        @include media-breakpoint-up(md) {
            margin: $grid-gutter-width $simplebar-offset $grid-gutter-width 0;
        }
    }

    @include media-breakpoint-down(md) {
        .dl-all {
            width: 100%;
        }
    }

    .simplebar-offset {
        margin-right: $simplebar-offset;
    }

    .simplebar-track {
        background-color: rgba($black, .1);
    }
}
