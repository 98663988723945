// Font-face
// --------------------------

// Generates an `@font-face` declaration. You can choose the specific file
// formats you need to output; the mixin supports `eot`, `ttf`, `otf`, `svg`, `woff2` and `woff`.
//
// @argument {string} $font-family
//
// @argument {string} $file-path
//
// @argument {string | list} $file-formats [("ttf", "woff2", "woff")]
//   List of the font file formats to include.
//
// @argument {float} $version [1.0]
//   Will append an parameter with the version number at the end of the url (?v=$version)
//
// @content
//   Any additional CSS properties that are included in the `@include`
//   directive will be output within the `@font-face` declaration, e.g. you can
//   pass in `font-weight`, `font-style` and/or `unicode-range`.
//
// @example scss
//   @include font-face(
//     "source-sans-pro",
//     "fonts/source-sans-pro-regular",
//     ("woff2", "woff")
//   ) {
//     font-style: normal;
//     font-weight: 400;
//   }
//
//   // CSS Output
//   @font-face {
//     font-family: "source-sans-pro";
//     src: url("fonts/source-sans-pro-regular.woff2") format("woff2"),
//          url("fonts/source-sans-pro-regular.woff") format("woff");
//     font-style: normal;
//     font-weight: 400;
//   }
//
// @require {function} _font-source-declaration
@mixin font-face(
    $font-family,
    $file-path,
    $file-formats,
    $version: false
) {
    $ie: contains($file-formats, "eot");
    $formats: if($ie, prepend(remove($file-formats, "eot"), "local"), $file-formats);

    @font-face {
        font-family: $font-family;
        @if $ie {
            src:
                font-source-declaration(
                    $font-family,
                    $file-path,
                    "eot",
                    $version
                );
        }
        src:
            font-source-declaration(
                $font-family,
                $file-path,
                $formats,
                $version
            );
        font-display: swap;
        @content;
    }
}
