// Bootstrap 5 - default
.form-control:not([type="checkbox"]):not([type="radio"]):not(textarea),
.form-select {
    padding-right: 0;
    padding-left: 0;
    border-width: $input-border-width 0;

    &,
    &:focus {
        border-top-color: transparent;
    }
}

.form-control:focus {
    border-color: $secondary;
}

.form-select {
    @include transition($input-transition);
    cursor: pointer;
}

.form-check-input {
    &:focus {
        border-color: $secondary;
    }
}

.form-check-input:checked {
    background-size: 8px;
    border-color: $secondary;

    &[type="radio"] {
        background-size: 16px;
    }

    &[type="checkbox"] {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Cpath d='M0 0h10v10H0z' fill='%23c00'/%3E%3C/svg%3E");
    }

    &:focus {
        border-color: $secondary;
    }
}

// not Bootstrap 5 - default
$fs-focus-label: px-to-rem(13px);
$fs-transform-label: ($fs-focus-label * $input-line-height);

%form-lable-styled--materialize {
    // use code from bootstrap 5 .form-control and modify
    @include font-size($input-font-size);
    @include transition(all .15s ease-in-out);

    position: absolute;
    top: $input-border-width;
    display: block;
    width: 100%;
    padding: $input-padding-y $input-padding-x;
    font-family: $input-font-family;
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    pointer-events: none; // can't select text (same like placeholder)
}

.form-item {
    position: relative;

    &,
    + p {
        margin-top: $fs-transform-label + ($grid-gutter-width * .5);
    }

    &.form-type-textarea {
        margin-top: px-to-rem(45px);
    }

    &.form-check:not(:first-of-type) {
        margin-top: $grid-gutter-width * .5;
    }

    &:not(.form-check) + .form-check {
        margin-top: $grid-gutter-width;
    }

    // adding (Materializ effect on input fields) :: START ----
    .form-control,
    .form-select {
        ~ label {
            @extend %form-lable-styled--materialize;
        }
    }

    .form-control:not(textarea),
    .form-select {
        ~ label {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .form-control {
        &:focus,
        &:not(:placeholder-shown) {
            ~ label {
                font-size: $fs-focus-label;
                font-weight: $font-weight-light;
                opacity: .5;
                transform: translateY(-$fs-transform-label);
            }
        }
    }

    .form-select {
        &:not(.form-select--selected) {
            color: rgba($black, 0);

            option {
                color: $black;
            }
        }

        &.form-select--selected ~ label {
            font-size: $fs-focus-label;
            font-weight: $font-weight-light;
            opacity: .5;
            transform: translateY(-$fs-transform-label);
        }
    }

    textarea.form-control { // stylelint-disable-line selector-no-qualifying-type
        &:focus,
        &:not(:placeholder-shown) {
            ~ label {
                padding-left: 0;
                transform: translateY(-($fs-transform-label + $input-padding-y + (px-to-rem($input-border-width * 2))));
            }
        }
    }

    // ---- END :: Materialize style
}

.form-managed-file {
    //&.form-managed-file--uploaded,
    ~ label {
        @include sprite("sprite-client-general-attachement", (
            color: $primary
        ));
        @extend %form-lable-styled--materialize;
        padding-right: 35px;
        padding-left: 0;
        background-position-x: right;
        background-size: 25px;
        border-bottom: $input-border-width solid $input-border-color;
    }

    .form-file {
        opacity: 0;
    }

    .form-submit {
        margin-bottom: $grid-gutter-width * .5;
    }

    // fix button style
    .btn:not([class*="btn-"]) {
        @extend .btn-outline-primary;
    }
}

.job_filter_form {
    display: flex;
    flex-wrap: wrap;

    .form-item {
        flex: 0 0 auto;
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
    }

    @include media-breakpoint-up(md) {
        margin-right: -$grid-gutter-width * .5;
        margin-left: -$grid-gutter-width * .5;

        .form-item {
            width: 50%;
            padding-right: $grid-gutter-width * .5;
            padding-left: $grid-gutter-width * .5;
        }

        .form-submit {
            margin-right: $grid-gutter-width * .5;
        }
    }
}

.job_filter_form .form-submit,
.paragraph--form .form-submit {
    margin-top: $paragraph-margin-bottom;
}

// Bootstrap Validation
.was-validated .form-control:invalid,
.was-validated .form-select:invalid,
.form-control.is-invalid,
.form-select.is-invalid {
    &:focus {
        border-color: $input-focus-border-color;
        box-shadow: none;
    }

    ~ label {
        color: $form-feedback-invalid-color;
    }
}
