/**
 * only style for node--type-reference
 */
.node--type-reference {
    @extend %node--type-page;

    .card {
        @extend %card-img-cover;
    }

    .card-title {
        color: $secondary;
    }

    .card-body {
        height: 100%;
    }
}
