.site-footer {
    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
    font-size: px-to-rem(14px);
    color: $white;
    background-color: $site-footer-bg-color;

    @include media-breakpoint-up(sm) {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    hr {
        height: 3px;
        margin: .5rem 0;
        color: $secondary;
        opacity: 1;
    }

    svg {
        color: $white;
        @include transition();
    }

    a {
        @include transition();
        color: $footer-nav-link-color;

        &:focus,
        &:hover {
            color: $footer-nav-link-hover-color;

            svg {
                color: $secondary;
            }
        }
    }

    .footer-bottom__first {
        padding-bottom: $grid-gutter-width;

        @include media-breakpoint-up(md) {
            padding-bottom: $site-footer-padding-t / 2;
        }
    }

    .footer-bottom__second {
        display: flex;
        flex-wrap: wrap;
        row-gap: $grid-gutter-width;
        padding-bottom: 0;
        margin: 0 calc(-#{$grid-gutter-width} / 2);

        .footer-bottom__second__item,
        .footer-nav,
        .footer-2-nav {
            flex: 1 1 auto;
            flex-basis: 100%;
            max-width: 100%;
            padding: 0 calc(#{$grid-gutter-width} / 2);

            @include media-breakpoint-up(sm) {
                flex-basis: 33%;
                max-width: 33%;
            }

            @include media-breakpoint-up(lg) {
                flex-basis: 20%;
                max-width: 20%;
            }

            &.logo-item-hekuma {
                flex-basis: 50%;
                align-items: center;
                order: 1;
                max-width: 50%;

                @include media-breakpoint-up(lg) {
                    flex-basis: 20%;
                    order: unset;
                    max-width: 20%;
                }
            }

            &.certifications {
                order: 5;

                @include media-breakpoint-up(lg) {
                    order: unset;
                }
            }

            &.logo-item-durr {
                display: flex;
                flex-basis: 50%;
                justify-content: flex-end;
                order: 2;
                max-width: 50%;

                @include media-breakpoint-up(lg) {
                    flex-basis: 20%;
                    order: unset;
                    max-width: 20%;
                }
            }
        }

        .footer-nav {
            order: 3;

            @include media-breakpoint-up(lg) {
                order: unset;
            }
        }

        .footer-2-nav {
            order: 4;

            @include media-breakpoint-up(lg) {
                order: unset;
            }
        }
    }

    .footer--social {
        border-top: 0;

        @include media-breakpoint-down(md) {
            padding-top: $grid-gutter-width;
        }

        svg {
            width: auto;
            height: 18px;

            @include media-breakpoint-up(md) {
                height: 28px;
            }
        }
    }

    .company-slogan-wrapper {
        text-align: right;

        .company__slogan {
            font-size: 1.25rem;
        }
    }
}

.site-footer__copyright {
    font-size: $small-font-size;
    text-align: center;
}
