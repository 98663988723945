// Stage
// --------------------------
.stage {
    position: relative;

    .entity-moderation-form {
        display: none;
    }

    .media {
        text-align: center;
    }
}

.stage__content .inner {
    padding-top: $grid-gutter-width / 2;

    > *:last-child {
        margin-bottom: 0;
    }
}

.stage__headline,
.stage__subheadline {
    text-transform: uppercase;
    word-break: break-word;

    &:first-child {
        padding-top: $grid-gutter-width / 2;
    }
}

.stage__content--box,
.path-frontpage .stage__content {
    width: 100%;
    color: $white;

    .inner {
        display: flex;
        flex-direction: column;
    }

    .stage__headline {
        padding-top: 0;
        font-weight: $font-weight-normal;
        line-height: 1;
        color: $white;
    }

    .stage__subheadline {
        order: -1;
        font-weight: $font-weight-normal;
        line-height: 1;
        color: $white;
    }
}

.path-frontpage .stage__content {
    position: absolute;
    bottom: 0;

    .stage__headline {
        margin-bottom: $grid-gutter-width;
        font-size: px-to-rem(40px);

        @include media-breakpoint-up(md) {
            @include font-size(90px);
            margin-bottom: $grid-gutter-width * 1.5;
        }

        @include media-breakpoint-up(lg) {
            @include font-size(110px);
        }
    }

    .stage__subheadline {
        margin-bottom: -5px;
        font-size: px-to-rem(20px);

        @include media-breakpoint-up(md) {
            @include font-size(35px);
            margin-bottom: -10px;
        }
    }

    .stage__description {
        display: none;
    }
}

.stage__headline--after {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: (-$grid-gutter-width / 2) (-$grid-gutter-width / 2) 0 (-$grid-gutter-width / 2);

    [class*="stage__item"] {
        margin: $grid-gutter-width / 2;
    }

    @include media-breakpoint-up(sm) {
        flex-direction: row;

        [class*="stage__item"] + [class*="stage__item"] {
            &::before {
                position: relative;
                top: -2px;
                left: (-$grid-gutter-width / 2) + px-to-rem(1px);
                display: inline-block;
                color: rgba($body-color, .7);
                content: "|";
            }
        }
    }
}

.stage--meta-date {
    @extend %heading;
    margin-bottom: px-to-rem(5px);
    font-weight: $font-weight-bold;
    line-height: $h2-line-height;
    text-transform: uppercase;
}

.stage--meta-location {
    margin-bottom: $grid-gutter-width * .25;
    font-weight: $font-weight-bold;
    line-height: $h2-line-height;
    color: $headings-color;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
        @include font-size($h3-font-size);
        margin-bottom: $grid-gutter-width * .5;
    }
}

/**
 * special stage design - with Text Box
 */
.stage__content--box {
    @include media-breakpoint-down(sm) {
        .container {
            padding: 0;
            margin: 0;
        }
    }

    @include media-breakpoint-up(sm) {
        position: absolute;
        bottom: 0;
    }

    .inner {
        display: inline-block;
        width: 100vw;
        max-width: 100%;
        padding: 25px;
        background-color: $dark-grey;

        @include media-breakpoint-up(sm) {
            margin-bottom: 35px;
            background-color: rgba($dark-grey, .9);
        }

        @include media-breakpoint-up(md) {
            width: auto;
            max-width: 80ch;
            padding: 40px;
            margin-bottom: 50px;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 70px;
        }

        @include media-breakpoint-only(xxl) {
            position: absolute;
            bottom: 0;
            left: 0;
        }

        @media (min-width: $screen-design-max-width) {
            position: relative;
            left: 0;
            margin-left: -240px;
        }
    }

    .stage__headline {
        margin-bottom: $grid-gutter-width * .5;

        @include media-breakpoint-up(md) {
            @include font-size(55px);
        }
    }

    .stage__subheadline,
    .stage__description {
        margin: 0;

        @include media-breakpoint-up(md) {
            @include font-size(24px);
        }
    }

    &--dark {
        .inner {
            background-color: rgba($black, .3);
        }
    }
}
