.grid {
    @include make-row();

    --#{$variable-prefix}gutter-y: #{$grid-gutter-width};

    @include media-breakpoint-down(md) {
        > .media.grid-item {
            --#{$variable-prefix}gutter-y: #{($spacer / 2)}; // same gy-2
        }
    }

    > .grid-item {
        @include make-col-ready();
        @include make-col(12);

        @include media-breakpoint-up(sm) {
            @include make-col(6);
        }

        @include media-breakpoint-up(md) {
            @include make-col(4);
        }
    }
}

