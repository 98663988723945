@mixin paragraph-whitespace($y, $min) {
    padding-bottom: 1px; // fix that spacing after headline is fixed and ignore negative margin from next element
    margin-top: $y;
    margin-bottom: $y;

    //&:not(.paragraph--is-first) {
    //    margin-top: $y;
    //}

    &[class*="theme-"] {
        padding-top: $y;
        padding-bottom: $y;
        margin-top: 0;
        margin-bottom: 0;
    }

    // [special] all text paragraph after stage remove spacing and add spacing if headline used
    &.paragraph--is-first {
        margin-top: 2rem;
    }

    // [addon] remove paragraph--headline margin and spacing
    &:not([class*="theme-"]).paragraph--headline + .paragraph:not([class*="theme-"]) {
        margin-top: -$y;
    }

    // [extra] adding missing negative margin from paragraph row (bootstrap gutter)
    &:not([class*="theme-"]).paragraph--headline + .paragraph--content-teaser:not([class*="theme-"]) .paragraph__content-container .row:first-child {
        position: relative;
        top: calc(var(--#{$variable-prefix}gutter-y) * -1);
    }

    // [experimental] reduce spacing between same paragraph-theme
    //&:not([class*="theme-"]) + &:not([class*="theme-"]) {
    //    margin-top: -$y;
    //
    //    // [experimental] reduce spacing between same paragraph-theme (adding missing space)
    //    //.container > *:first-child {
    //    > * {
    //        padding-top: $min;
    //
    //        //&.paragraph__headline {
    //        .paragraph__headline {
    //            padding-top: ($y - $min);
    //        }
    //    }
    //}

    @each $color, $value in $customer-theme-colors {
        &.theme-#{$color} + &.theme-#{$color} {
            // remove spacing between same paragraph-theme
            padding-top: 0;
            // [experimental] reduce spacing between same paragraph-theme
            //margin-top: -$y;

            // [experimental] reduce spacing between same paragraph-theme (adding missing space)
            //.container > *:first-child {
            //> * {
            //    padding-top: $min;
            //
            //    //&.paragraph__headline {
            //    .paragraph__headline {
            //        padding-top: ($y - $min);
            //    }
            //}
        }

        // [addon] remove paragraph--headline margin and spacing
        &.theme-#{$color}.paragraph--headline + &.theme-#{$color} {
            margin-top: -$y;
        }

        @if ($color == "light") {
            &.theme-#{$color} {
                .card--gray .card-body,
                .card .card-body {
                    background-color: $white;
                }
            }
        }

        // [extra] adding missing negative margin from paragraph row (bootstrap gutter)
        &:not([class*="theme-"]).paragraph--headline + .paragraph--content-teaser:not([class*="theme-"]) .paragraph__content-container .row:first-child {
            position: relative;
            top: calc(var(--#{$variable-prefix}gutter-y) * -1);
        }
    }
}
