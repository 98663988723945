// placeholder
%card-img-cover {
    figure {
        height: 100%;

        .img-fluid {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

// style
.card {
    height: 100%;
    background-color: transparent;

    .media img {
        @include transition();
        transform: scale(1.01);
    }

    &:hover,
    &:focus {
        .media img { // stylelint-disable-line selector-max-compound-selectors
            transform: scale(1.05);
        }
    }

    /**
     *  Style for view-mode
     */
    &.node--view-mode-embedded,
    &.node--view-mode-accordion {
        .card-body {
            height: 100%;
        }
    }
}

// Button outline white extended from theme-primary
.paragraph:not(.theme-primary) .card {
    .btn-outline-white {
        @include button-outline-variant($black, $lighter-grey);
    }
}

.card-img,
.card-img-top,
.card-body,
.card-img-overlay--bg,
.card > .row {
    @include transition();
    background-color: $card-bg;
}

.card-img,
.card-img-top {
    text-align: center;
}

.card-body {
    @include media-breakpoint-down(md) {
        padding: px-to-rem(12px) px-to-rem(15px);
    }

    p {
        color: currentColor;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.card--gray {
    @if $card-border-width > 0 {
        border-color: darken($gray-500, 20%);
    }

    .card-img,
    .card-img-top,
    .card-body,
    > .row {
        color: $black;
        background-color: $teaser-grey;
    }

    //.btn-outline-white {
    //    @include button-outline-variant($black, $gray-100);
    //}
}

.card-tag {
    $card-tag-px: .5625rem;
    @include text-truncate();

    position: relative;
    z-index: 2;
    align-self: flex-start;
    max-width: 80%;
    padding: $card-tag-px 20px $card-tag-px 12px;
    font-weight: $font-weight-bold;
    line-height: 1;
    color: $white;
    text-transform: uppercase;
    pointer-events: none;
    background-color: $primary-dark;

    a:not(.btn) {
        color: currentColor;
        text-decoration: none;

        &:hover,
        &:focus {
            color: currentColor;
            text-decoration: underline;
        }
    }
}

.card-img-overlay--bg {
    @extend .card-img-overlay;
    // FIX that not extend from bootstrap anymore !!!!! - added same code from bootstrap
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: $card-img-overlay-padding;
    @include border-radius($card-inner-border-radius);
}

.node--view-mode-teaser {
    .card-title {
        color: $body-color;
    }
}
