.lazyload,
.lazyloading {
    background-color: $gray-300;
}

.lazyloading-picture {
    position: relative;
    display: flex;
}

.lazyloading-bg {
    position: relative;
}

img {
    // This styling prevent alt-attributes get displayed
    // but it is visible while .lazyloading
    // stylelint-disable-next-line selector-no-qualifying-type
    &.lazyload:not([src]) {
        visibility: hidden;
    }
}
