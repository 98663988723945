.paragraph--search {
    .form-item {
        margin-top: 2px;
    }
}

.paragraph--result {
    .card {
        &:hover,
        &:focus {
            .card-body {
                background-color: $gray-500;
            }
        }
    }

    .card-text {
        color: rgba($body-color, .75);
    }
}
