// DISABLED
// --------------------------
.disabled {

    //disable body if browser-update is visible
    @at-root body#{&} {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
}
