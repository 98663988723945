// Swiper
// --------------------------
@import "node_modules/swiper/swiper";
//IMPORT_COMPONENTS
@import "node_modules/swiper/components/a11y/a11y";
@import "node_modules/swiper/components/controller/controller";
@import "node_modules/swiper/components/effect-coverflow/effect-coverflow";
@import "node_modules/swiper/components/effect-fade/effect-fade";
@import "node_modules/swiper/components/lazy/lazy";
@import "node_modules/swiper/components/navigation/navigation";
@import "node_modules/swiper/components/pagination/pagination";
@import "node_modules/swiper/components/scrollbar/scrollbar";
@import "node_modules/swiper/components/thumbs/thumbs";

// Swiper component overrides - navigation
// --------------------------
// 1. replace with own variables
// 2. rename variable
// 3. add own function to be more flexible
.swiper-button-prev,
.swiper-button-next {
    width: $slider-arrow-width; // [1]
    height: $slider-arrow-height; // [1]
    margin-top: -($slider-arrow-height * .5); // [1]
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: $slider-arrow-width $slider-arrow-height; // [1]
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    &::after {
        content: none;
    }

    right: auto;
    left: $slider-navigation-offset; // [1]
    background-image: swiper-svg-uri(slider-arrow($slider-arrow-prev, $slider-arrow-width, $slider-arrow-height, $swiper-theme-color)); // [3]
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    &::after {
        content: none;
    }

    right: $slider-navigation-offset; // [1]
    left: auto;
    background-image: swiper-svg-uri(slider-arrow($slider-arrow-next, $slider-arrow-width, $slider-arrow-height, $swiper-theme-color)); // [3]
}

@each $navColorName, $navColorValue in $swiper-colors { // [2]
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        &.swiper-button-#{$navColorName} {
            background-image: swiper-svg-uri(slider-arrow($slider-arrow-next, $slider-arrow-width, $slider-arrow-height, $navColorValue)); // [3]
        }
    }
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        &.swiper-button-#{$navColorName} {
            background-image: swiper-svg-uri(slider-arrow($slider-arrow-next, $slider-arrow-width, $slider-arrow-height, $navColorValue)); // [3]
        }
    }
}

// Swiper component - pagination
// --------------------------
// 1. replace with own variables
// 2. add extra line with + operator for custom markup
// 3. rename variable

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: $slider-navigation-offset; // [1]
}

.swiper-pagination-bullet {
    width: $slider-pagination-bullet-width; // [1]
    height: $slider-pagination-bullet-height; // [1]
    @include border-radius($slider-pagination-bullet-border-radius); // [1]
}

.swiper-container-vertical {
    > .swiper-pagination-bullets,
    + .swiper-pagination-bullets { // [2]
        top: 50%;
        right: $slider-navigation-offset; // [1]
        transform: translate3d(0, -50%, 0);

        .swiper-pagination-bullet {
            display: block;
            margin: $slider-pagination-bullet-margin-x * 1.5 0; // [1]
        }

        &.swiper-pagination-bullets-dynamic {
            top: 50%;
            width: $slider-pagination-bullet-width; // [1]
            transform: translateY(-50%);

            .swiper-pagination-bullet {
                display: inline-block;
                @include transition(200ms transform, 200ms top);
            }
        }
    }
}

.swiper-container-horizontal {
    > .swiper-pagination-bullets,
    + .swiper-pagination-bullets { // [2]
        .swiper-pagination-bullet {
            margin: 0 $slider-pagination-bullet-margin-x; // [1]
        }

        &.swiper-pagination-bullets-dynamic {
            left: 50%;
            white-space: nowrap;
            transform: translateX(-50%);

            .swiper-pagination-bullet {
                @include transition(200ms transform, 200ms left);
            }
        }
    }

    &.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
        @include transition(200ms transform, 200ms right);
    }
}

/* Progress */
@each $paginationColorName, $paginationColorValue in $swiper-colors { // [3]
    .swiper-pagination-#{$paginationColorName} {
        --swiper-pagination-color: #{$paginationColorValue};
    }
}

// start own slider styles here
// --------------------------
.slider-container {
    position: relative;
}

.swiper-container {
    // @TODO look for a better way to achieve this goal
    .img-fluid {
        width: 100%;
    }
}

/* stylelint-disable-next-line selector-class-pattern */
.slider-controls-position {

    &-outside {

        .swiper-pagination {
            bottom: 0;
            width: 100%;
            margin-top: $slider-navigation-offset * 2 + $slider-pagination-bullet-height;
        }

        @each $breakpoint in map-keys($grid-breakpoints) {
            $min: breakpoint-min($breakpoint);
            $max: breakpoint-max($breakpoint);

            @if $min != null {
                @if $max == null {
                    @include media-breakpoint-up($breakpoint) {
                        .swiper-button-prev,
                        .swiper-container-rtl .swiper-button-next {
                            left: -($slider-navigation-offset + $slider-arrow-width);
                        }

                        .swiper-button-next,
                        .swiper-container-rtl .swiper-button-prev {
                            right: -($slider-navigation-offset + $slider-arrow-width);
                        }
                    }
                } @else {
                    @include media-breakpoint-up($breakpoint) {
                        .swiper-button-prev,
                        .swiper-container-rtl .swiper-button-next {
                            left: $slider-navigation-offset;
                        }

                        .swiper-button-next,
                        .swiper-container-rtl .swiper-button-prev {
                            right: $slider-navigation-offset;
                        }
                    }
                }
            }

            @if $min != null and $max != null {
                @media (min-width: map-get($container-max-widths, $breakpoint) + $scrollbar-width + (($slider-navigation-offset + $slider-arrow-width) * 2) - rem-to-px($grid-gutter-width)) and (max-width: $max) {
                    .swiper-button-prev,
                    .swiper-container-rtl .swiper-button-next {
                        left: -($slider-navigation-offset + $slider-arrow-width);
                    }

                    .swiper-button-next,
                    .swiper-container-rtl .swiper-button-prev {
                        right: -($slider-navigation-offset + $slider-arrow-width);
                    }
                }
            }
        }
    }
}

@if ($slider-arrows-mobile == false) {
    @include media-breakpoint-only(xs) {
        .swiper-button-prev,
        .swiper-container-rtl .swiper-button-next,
        .swiper-button-next,
        .swiper-container-rtl .swiper-button-prev {
            display: none;
        }
    }
}

