// IMAGES
// --------------------------

// Fluid images for responsive purposes.
@if ($images-fluid == true) {
    img {
        &,
        figure & {
            @include img-fluid();
        }
    }
}

// Gives svgs in internet explorer a width
@include ie-only() {
    // stylelint-disable-next-line selector-no-qualifying-type
    img[src$=".svg"] {
        width: 100%;
    }
}

// In case you don't have control over generated `width` and `height` attributes
// on `<img>` elements in your markup, but still want the images to be fluid,
// set this to `false`.
@if ($images-static == true) {
    /**
	 * If a `width` and/or `height` attribute has been explicitly defined, let’s
	 * not make the image fluid.
	 */
    // stylelint-disable selector-no-qualifying-type
    img[width],
    img[height] {
        &,
        &.img-fluid {
            max-width: none;
        }
    }
    // stylelint-enable selector-no-qualifying-type
}
