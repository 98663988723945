/* Globally enable grid prefixes */

/* autoprefixer grid: autoplace */
.info-tile__list {
    @include media-breakpoint-up(md) {
        display: grid;
        gap: 2px;
        grid-auto-rows: var(--rowHeight);
        grid-template-columns: repeat(3, 1fr);
    }
    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.info-tile {
    position: relative;

    @include media-breakpoint-down(md) {
        padding-right: var(--#{$variable-prefix}gutter-x, .75rem);
        padding-left: var(--#{$variable-prefix}gutter-x, .75rem);
    }

    > img {
        object-fit: cover;
        height: 100%;
    }

    .swiper-scrollbar {
        width: 70vw;
        max-width: 300px;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}

.info-tile--open {
    z-index: $zindex-modal;
}

.info-tile__item {
    position: relative;
    display: block;
    cursor: pointer;
    background-color: $primary;

    .info-tile {
        &__content {
            @include transition();
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 20px;
            color: rgba($secondary, .8);

            @include media-breakpoint-up(xl) {
                padding: 50px $grid-gutter-width;
            }

            &:hover {
                color: $black;
                background-color: $secondary;

                .btn-outline-white {
                    @include button-outline-variant($black, $secondary);
                }
            }
        }

        &__title {
            margin-bottom: 8px;
            font-size: 10vw;
            font-weight: $font-weight-extra-bold;
            line-height: 1;
            word-break: break-word;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
                font-size: 3vw;
            }

            @include media-breakpoint-up(lg) {
                font-size: 2.6vw; // ~ 50px
            }

            @media (min-width: $screen-design-max-width) {
                font-size: 50px;
            }
        }
    }
}

/* Large Overlay */
.info-tile__flyer {
    @include transition();
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    max-height: 100%;
    overflow: hidden;
    color: $white;
    visibility: hidden;
    background-color: $primary;
    opacity: 0;

    @include media-breakpoint-up(md) {
        position: absolute;
    }

    &-content {
        display: flex;
        height: 100%;

        @include media-breakpoint-up(md) {
            align-items: flex-end;
        }
    }

    .content {
        max-width: 1680px;
        margin: 80px $grid-gutter-width $grid-gutter-width;

        @include media-breakpoint-up(md) {
            margin: 100px 100px 50px 70px;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: 70px;
        }

        > * {
            word-break: break-word;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    figure {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .img-fluid {
        height: 100%;
        object-fit: cover;
    }

    .caption {
        font-size: 16px;
        line-height: 1.1429;
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
            max-width: 650px;
            font-size: 28px;
        }

        @include media-breakpoint-up(xl) {
            max-width: 900px;
            font-size: 35px;
        }
    }

    .info-tile__title {
        font-size: 40px;
        font-weight: $font-weight-extra-bold;
        line-height: 1;
        color: rgba($secondary, .8);
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
            font-size: 8.3333vw; // ~160px
        }

        @media (min-width: $screen-design-max-width) {
            font-size: 160px;
        }
    }

    .desc {
        max-width: 1100px;
        font-size: 18px;
        font-weight: $font-weight-light;

        @include media-breakpoint-up(lg) {
            font-size: 21px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 24px;
        }
    }

    &--open {
        visibility: visible;
        opacity: 1;
    }

    &-close {
        @include transition();
        position: absolute;
        top: 30px;
        right: 30px;
        z-index: $zindex-popover;
        cursor: pointer;

        svg {
            width: 30px;
            height: 30px;
        }

        &:hover,
        &:focus {
            color: $black;
        }

        @include media-breakpoint-up(md) {
            top: 50px;
            right: 50px;

            svg {
                width: 50px;
                height: 50px;
            }
        }
    }
}

/* autoprefixer grid: off */
