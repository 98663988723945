// Default table style if no js enabled
table:not(.table) {
    th,
    td {
        padding: $grid-gutter-width / 2;
        border: $table-border-width solid $black;
    }
}

// general style
table {
    border-spacing: 0;
    border-collapse: separate;

    @include media-breakpoint-down(xl) {
        &:not(.table--custom) {
            th,
            td {
                min-width: 270px;
            }
        }
    }

    //@include media-breakpoint-only(xs) {
    //    &:not(.table--custom) {
    //        th,
    //        td {
    //            max-width: calc(100vw - #{$grid-gutter-width});
    //            white-space: nowrap;
    //        }
    //    }
    //}
}

th,
td {
    > *:last-child {
        margin-bottom: 0;
    }
}

.table--fixed {
    table-layout: fixed;
}

//$cell-min-width-xs: 43.75vw; // ~ 50% of viewport (no grid-gutter-width)
$cell-min-width-xs: px-to-rem(80px); // set min-width for every col
$cell-min-width: px-to-rem(170px); // set min-width for every col
$cell-min-width-first-col-xs: px-to-rem(130px); // set min-width only for first col
$cell-min-width-first-col: px-to-rem(240px); // set min-width only for first col
$cell-max-width-first-col: px-to-rem(400px); // set max-width only for first col
// set only min-width if breakpoint bigger than 2 cols can displayed
$media-breakpoint: $cell-min-width + $cell-min-width-first-col + $grid-gutter-width;

// Bootstrap styled table
@include media-breakpoint-up(lg) {
    .table--2col > :not(caption) > * > *:first-child { // stylelint-disable-line selector-max-universal
        width: $cell-max-width-first-col;
        min-width: $cell-max-width-first-col;
    }
}

.table--custom {
    --#{$variable-prefix}table-bg: #{$table-custom-bg};
    --#{$variable-prefix}table-striped-bg: #{$table-custom-striped-bg};
    border-color: $table-custom-border-color;

    > :not(caption) > * {
        // stylelint-disable selector-max-universal
        > * {
            min-width: $cell-min-width-xs;

            @include media-breakpoint-down(md) {
                padding: $grid-gutter-width / 2;
            }

            @media (max-width: ($media-breakpoint - 1)) {
                width: $cell-min-width-xs;

                &:first-child {
                    width: $cell-min-width-first-col-xs;
                    min-width: $cell-min-width-first-col-xs;
                    max-width: $cell-min-width-first-col-xs;
                }
            }

            @media (min-width: $media-breakpoint) {
                min-width: $cell-min-width;
                padding-bottom: $table-cell-padding-y / 2;

                &:first-child {
                    min-width: $cell-min-width-first-col;
                    max-width: $cell-max-width-first-col;
                }
            }

            &:not(:first-child) {
                text-align: center;
            }

            &:not(:last-child) {
                border-right-width: $table-border-width;
            }
        }
        // stylelint-enable selector-max-universal

        > th:not(:first-child) {
            vertical-align: middle;
        }
    }

    > :not(:last-child) > :last-child > * {
        border-bottom-color: inherit;
    }

    th {
        font-weight: $font-weight-normal;
    }

    tr {
        &:nth-of-type(#{$table-striped-order}) {
            --#{$variable-prefix}table-accent-bg: #{$table-striped-bg};
        }

        > th {
            color: $black;
            background-color: $secondary;

            &:first-of-type {
                color: $white;
                background-color: $primary;
                box-shadow: none;
            }
        }
    }

    @include media-breakpoint-up(md) {
        tr > *:first-child {
            position: sticky;
            left: 0;
        }
    }
}
