.dropdown-menu {
    text-transform: uppercase;
    border-width: $dropdown-border-width 0 0 0;
}

.dropdown-item {
    @include transition();
}

.dropdown-toggle {
    &::before {
        @include sprite("sprite-client-general-chevron-down", (
            color: $dropdown-icon-color
        ));
        @include transition();

        display: inline-block;
        width: 10px;
        height: 6px;
        margin-right: 4px;
        content: "";
    }

    &:hover,
    &[aria-expanded="true"] {
        &,
        &::before {
            color: $dropdown-link-hover-color;
        }

        &::before {
            @include sprite("sprite-client-general-chevron-down", (
                color: $dropdown-link-hover-color
            ));
        }
    }
}
