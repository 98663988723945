// Accordion
// --------------------
.accordion {

    > .card {
        // Extend Collapse Styles
        background-color: $collapse-card-bg;
        border: $collapse-card-border-width solid $collapse-card-border-color;

        @include border-radius($collapse-card-border-radius);

        > .list-group:first-child {
            .list-group-item:first-child {
                @include border-top-radius($collapse-card-border-radius);
            }
        }

        > .list-group:last-child {
            .list-group-item:last-child {
                @include border-bottom-radius($collapse-card-border-radius);
            }
        }

        .card-header {
            // set padding and background-color to the link to make the whole title clickable
            padding: 0;
            background-color: transparent;

            .btn-link {
                display: block;
                width: 100%;
                padding: $collapse-card-spacer-y $collapse-card-spacer-x;
                color: $accordion-active-link-color;
                background-color: $collapse-card-cap-bg-open;

                // stylelint-disable-next-line selector-max-class
                &.collapsed {
                    background-color: $collapse-card-cap-bg;
                }
            }
        }

        .card-body {
            padding: $collapse-card-spacer-x;
        }

        .card-title {
            margin-bottom: $collapse-card-spacer-y;
        }

        // Reset bootstrap accordion cards because it won't work with our markup
        &:not(:first-of-type) {
            .card-header:first-child {
                @include border-radius($card-border-radius);
            }

            &:not(:last-of-type) {
                border-bottom: $card-border-width solid $card-border-color;
                @include border-radius($card-border-radius);
            }
        }

        &:first-of-type {
            border-bottom: $card-border-width solid $card-border-color;
            @include border-bottom-radius($card-border-radius);
        }

        &:last-of-type {
            @include border-top-radius($card-border-radius);
        }

        // Rewrite Bootstrap accordion to classes that it will work with our markup
        &:not(.first) {
            .card-header:first-child {
                @include border-radius(0);
            }

            &:not(.last) {
                border-bottom: 0;
                @include border-radius(0);
            }
        }

        &.first {
            border-bottom: 0;
            @include border-bottom-radius(0);
        }

        &.last {
            @include border-top-radius(0);
        }
    }
}
