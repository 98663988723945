// overwrite hardcoded jQuery-Ui Dialog (Drupal used)
// stylelint-disable declaration-no-important
.ui-dialog {
    @include transition($modal-transition);
    top: 0 !important;
    left: 0 !important;
    z-index: $zindex-modal !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    height: 100% !important;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background: transparent;
    //transform: $modal-fade-transform;

    > * {
        width: 100% !important; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
        max-width: $modal-lg;
        padding: $modal-header-padding !important;
        // counteract the pointer-events: none; in the .modal-dialog
        color: $modal-content-color;
        pointer-events: auto;
        background-color: $modal-content-bg !important;
        background-clip: padding-box !important;

        &:first-child {
            margin-top: $modal-dialog-margin-y-sm-up;
        }

        &:last-child {
            margin-bottom: $modal-dialog-margin-y-sm-up;
        }
    }
}

.ui-dialog-titlebar-close {
    position: initial !important;
    box-sizing: content-box;
    width: $btn-close-width !important;
    height: $btn-close-height !important;
    padding: $btn-close-padding-y $btn-close-padding-x !important;
    color: $btn-close-color;
    background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat; // include transparent for button elements
    border: 0; // for button elements
    @include border-radius();
    opacity: $btn-close-opacity;

    // Override <a>'s hover style
    &:hover {
        color: $btn-close-color;
        text-decoration: none;
        opacity: $btn-close-hover-opacity;
    }

    &:focus {
        outline: 0;
        box-shadow: $btn-close-focus-shadow;
        opacity: $btn-close-focus-opacity;
    }

    &:disabled,
    &.disabled {
        pointer-events: none;
        user-select: none;
        opacity: $btn-close-disabled-opacity;
    }
}

.ui-dialog-titlebar {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
    padding: $modal-header-padding !important;
    border-bottom: $modal-header-border-width solid $modal-header-border-color;
    border-top-left-radius: $modal-content-inner-border-radius !important;
    border-top-right-radius: $modal-content-inner-border-radius !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    .ui-dialog-titlebar-close {
        padding: ($modal-header-padding-y * .5) ($modal-header-padding-x * .5);
        margin: ($modal-header-padding-y * -.5) ($modal-header-padding-x * -.5) ($modal-header-padding-y * -.5) auto;
    }

    &::after,
    &::before {
        display: none !important;
    }
}

.ui-dialog-title {
    margin: 0 !important;
    line-height: $modal-title-line-height;
}

.ui-dialog-content {
    position: relative;
    // Enable `flex-grow: 1` so that the body take up as much space as possible
    // when there should be a fixed height on `.modal-dialog`.
    flex: 1 1 auto;
    max-height: 65vh !important;
    padding: $modal-inner-padding;
    border-bottom-right-radius: $modal-content-inner-border-radius !important;
    border-bottom-left-radius: $modal-content-inner-border-radius !important;
}
// stylelint-enable declaration-no-important
