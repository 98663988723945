// Nav-item
// --------------------------

// Set colors of nav element
@mixin nav-item-color($color, $bg-color, $color-hover, $bg-color-hover, $color-active, $bg-color-active, $color-current, $bg-color-current, $class-active: $nav-item-class-active, $class-current: $nav-item-class-current) {
    .nav-link {
        color: $color;
        background-color: $bg-color;

        &:hover,
        &:focus {
            color: $color-hover;
            background-color: $bg-color-hover;
        }

        // Disabled state lightens text
        &.disabled {
            color: lighten($color, 10);
            background-color: lighten($bg-color, 10);
        }
    }
    .nav-item {
        color: $color;

        &.#{$class-active} {
            .nav-link {
                color: $color-active;
                background-color: $bg-color-active;
            }
        }

        &.#{$class-current} {
            .nav-link {
                color: $color-current;
                background-color: $bg-color-current;
            }
        }
    }
}
