$container-indent-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 820px,
    xl: 860px,
    xxl: 900px
) !default;

@if $enable-grid-classes {
    .container-indent {
        @include make-container();

        @each $breakpoint, $container-max-width in $container-indent-max-widths {
            @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
                max-width: $container-max-width;
            }
        }
    }
}
