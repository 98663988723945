//	HTML
// --------------------------

// 1. Ensure the page always fills at least the entire height of the viewport.
// 2. Prevent horizontal scrollbars
// 3. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
//    navigating between pages that do/do not have enough content to produce
// 		scrollbars naturally.

html {
    min-height: 100%; // [1]
    overflow-x: hidden; // [2]
    overflow-y: scroll; // [3]
}
