/**
 * only for node-type-article // news
 */

.node--type-article {
    @extend %node--type-page;
    @extend %node--type-page--hide;

    .card-text {
        display: -webkit-box; // stylelint-disable-line value-no-vendor-prefix
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical; // stylelint-disable-line property-no-vendor-prefix
        overflow: hidden;
    }
}
