// Navigation footer
// --------------------------

// Should always be the nav object
.site-footer {
    strong + .nav {
        margin-top: $grid-gutter-width / 4;
    }

    .footer-nav .nav,
    .footer-2-nav .nav {
        flex-direction: column;
    }

    .nav-item {
        &:last-child {
            .nav-link {
                padding-bottom: 0;
            }
        }
    }

    .nav-link {
        padding: 0 0 ($grid-gutter-width / 4);
    }
}
