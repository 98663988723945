// Browser hacks
//
// Mixins to address special browsers
// --------------------------

// Media Query to address ie >= 10
@mixin ie-only() {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
}

// Supports function to address edge
@mixin edge-only() {
    @supports (-ms-ime-align:auto) {
        @content;
    }
}

// Safari 10.1+
@mixin safari-only() {
    @media not all and (min-resolution: .001dpcm) {
        @supports (-webkit-appearance:none) {
            @content;
        }
    }
}
