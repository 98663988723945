@use "sass:math";

// General settings
// --------------------------
$images-fluid: false !default;
$images-static: false !default;
$scrollbar-width: 20px !default; // generally 17px - we add some space
$rem-baseline: 16px !default;
$vw-baseline: 1200px !default;

// Fonts
// --------------------------

$font-system-stack-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$font-name-base: Arial !default;
$font-path-base: "../fonts" !default;

$font-name-second: "Times New Roman" !default;
$font-path-second: "../fonts" !default;

$font-base:   #{$font-name-base}, #{$font-system-stack-sans-serif} !default;
$font-second: #{$font-name-second}, #{$font-system-stack-sans-serif} !default;

$font-weight-medium:     500 !default;
$font-weight-semi-bold:  600 !default;
$font-weight-extra-bold: 800 !default;
$font-weight-black:      900 !default;


// Colors
// --------------------------
$mosaiq-color:      #d9d0ab !default; // MOSAIQ - HELLES BEIGE
$mosaiq-color-dark: #9d8c45 !default; // MOSAIQ - DUNKLES BEIGE


// Page wrapper
// --------------------------
$page-wrapper-width: 100% !default;
$page-wrapper-padding-r: 0 !default;
$page-wrapper-padding-l: 0 !default;
$page-wrapper-bg-color: transparent !default;


// Site
// --------------------------
$site-header-padding-t: 0 !default;
$site-header-padding-b: $site-header-padding-t !default;
$site-header-bg: transparent !default;

$sticky-header-height: 100px !default;
$sticky-header-scrolling-height: 57px !default;

$site-header-inner-margin-t: 0 !default;
$site-header-inner-margin-b: $site-header-inner-margin-t !default;

$site-footer-padding-t: 0 !default;
$site-footer-padding-b: $site-footer-padding-t !default;
$site-footer-bg-color: transparent !default;

// Navigation
// --------------------------
$nav-item-class-active:             "active" !default;
$nav-item-class-current:            "current" !default;

// MAIN
$primary-nav-breakpoint: xl !default;
$primary-nav-link-color:               $link-color !default;
$primary-nav-link-hover-color:         $link-hover-color !default;
$primary-nav-link-active-color:        $primary-nav-link-hover-color !default;
$primary-nav-link-current-color:       $primary-nav-link-active-color !default;
$primary-nav-link-bg:                  transparent !default;
$primary-nav-link-hover-bg:            transparent !default;
$primary-nav-link-active-bg:           $primary-nav-link-hover-bg !default;
$primary-nav-link-current-bg:          $primary-nav-link-active-bg !default;

// META
$secondary-nav-link-color:               $link-color !default;
$secondary-nav-link-hover-color:         $link-hover-color !default;
$secondary-nav-link-active-color:        $secondary-nav-link-hover-color !default;
$secondary-nav-link-current-color:       $secondary-nav-link-active-color !default;
$secondary-nav-link-bg:                  transparent !default;
$secondary-nav-link-hover-bg:            transparent !default;
$secondary-nav-link-active-bg:           $secondary-nav-link-hover-bg !default;
$secondary-nav-link-current-bg:          $secondary-nav-link-active-bg !default;

// FOOTER
$footer-nav-link-color:             $link-color !default;
$footer-nav-link-hover-color:       $link-hover-color !default;
$footer-nav-link-active-color:      $footer-nav-link-hover-color !default;
$footer-nav-link-current-color:     $footer-nav-link-active-color !default;
$footer-nav-link-bg:                transparent !default;
$footer-nav-link-hover-bg:          transparent !default;
$footer-nav-link-active-bg:         $footer-nav-link-hover-bg !default;
$footer-nav-link-current-bg:        $footer-nav-link-active-bg !default;


// MOBILE NAV BUTTON (Hamburger)
$mobile-nav-button-label-offset:             1rem !default;
$mobile-nav-button-icon-layer-width:         2.25rem !default;
$mobile-nav-button-icon-layer-height:        .1875rem !default;
$mobile-nav-button-icon-layer-spacing:       .5rem !default;
$mobile-nav-button-icon-layer-color:         $primary !default;
$mobile-nav-button-icon-hover-layer-color:   shift-color($mobile-nav-button-icon-layer-color, $link-shade-percentage) !default;
$mobile-nav-button-icon-active-layer-color:  $secondary !default;
$mobile-nav-button-icon-layer-border-radius: .1875rem !default;
$mobile-nav-button-icon-transition:          $transition-base !default;


// Throbber
// --------------------------
$throbber-color: $primary !default;
$throbber-size: 2rem !default;


// Settings for Components
// --------------------------

// Paragraph
$paragraphs-margin-top: 3rem !default;
$paragraphs-padding-top: 3rem !default;
$paragraphs-padding-bottom: 3rem !default;
$paragraphs-cols-gutter-x: $grid-gutter-width !default;
$paragraphs-cols-gutter-y: $grid-gutter-width !default;
$paragraphs-divider-height: 2 !default;
$paragraphs-divider-width: 7 !default;
$paragraphs-divider-factor: math.div(1, 3) !default;

// Teaser
$teaser-padding-y:        .5rem !default;
$teaser-padding-x:        .5rem !default;
$teaser-bg-color: $secondary !default;

$input-color:                           $body-color !default;
$teaser-border-color:                    $gray-400 !default;
$teaser-border-width:                    $input-btn-border-width !default;
$teaser-box-shadow: $box-shadow !default;

$teaser-border-radius:                   $border-radius !default;
$teaser-border-radius-sm:                $border-radius-sm !default;
$teaser-border-radius-lg:                $border-radius-lg !default;

$teaser-headline-margin-bottom:      $spacer * .5 !default;
$teaser-headline-font-size:          2rem !default;
$teaser-headline-font-family:        null !default;
$teaser-headline-font-weight:        700 !default;
$teaser-headline-line-height:        1.2 !default;
$teaser-headline-color:              null !default;

$teaser-subheadline-margin-bottom:      $spacer * .5 !default;
$teaser-subheadline-font-size:          1.5rem !default;
$teaser-subheadline-font-family:        null !default;
$teaser-subheadline-font-weight:        500 !default;
$teaser-subheadline-line-height:        1.2 !default;
$teaser-subheadline-color:              null !default;

$teaser-description-margin-bottom:      $spacer * .5 !default;
$teaser-description-font-size:          1rem !default;
$teaser-description-font-family:        null !default;
$teaser-description-font-weight:        400 !default;
$teaser-description-line-height:        1.2 !default;
$teaser-description-color:              null !default;

// Messages
$messages-list-margin-top:            $spacer !default;
$messages-list-margin-bottom:         $spacer !default;
$messages-messages-margin-top:        $spacer * 1.25 !default;
$messages-messages-margin-bottom:     $spacer * 1.25 !default;
$messages-messages-padding-y:         $spacer * 1.25 !default;
$messages-messages-padding-x:         $spacer * 1.25 !default;
$messages-messages-border-color:      $gray-400 !default;
$messages-messages-border-width:      $input-btn-border-width !default;
$messages-messages-border-left-width: $spacer * .25 !default;
$messages-messages-icon-width:        40px !default;
$messages-messages-icon-height:       40px !default;

// Accordion
$accordion-active-link-color:              $white !default;

// Collapse
$collapse-card-spacer-y:                   .75rem !default;
$collapse-card-spacer-x:                   1.25rem !default;
$collapse-card-border-width:               1px !default;
$collapse-card-border-radius:              5px !default;
$collapse-card-border-color:               rgba($black, .125) !default;
$collapse-card-inner-border-radius:        calc(#{$collapse-card-border-radius} - #{$collapse-card-border-width}) !default;
$collapse-card-cap-bg:                     rgba($black, .03) !default;
$collapse-card-cap-bg-open:                rgba($black, .73) !default;
$collapse-card-bg:                         $white !default;

// Swiper
$swiper-theme-color:                       $primary !default;
$swiper-colors:                            ("white": $white, "black": $black) !default; // quote color names
// Slider
// own variables
$slider-padding-y:                         $spacer !default;
$slider-textbox-padding-x:                 $spacer !default;
$slider-textbox-padding-y:                 $spacer !default;
$slider-textbox-transparency:              .7 !default;
$slider-navigation-offset:                 10px !default;
$slider-pagination-bullet-width:           8px !default;
$slider-pagination-bullet-height:          8px !default;
$slider-pagination-bullet-border-radius:   100% !default;
$slider-pagination-bullet-margin-x:        4px !default;
$slider-arrows-mobile:                     true !default;
$slider-arrow-width:                       27px !default;
$slider-arrow-height:                      44px !default;
$slider-arrow-prev:                        "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 %width %height'><path d='M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z' fill='%color'/></svg>" !default;
$slider-arrow-next:                        "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 %width %height'><path d='M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z' fill='%color'/></svg>" !default;

// Z-Indexes
// --------------------------
$zindex-sticky-header: 1031 !default;
$zindex-to-top:       2000 !default;
$zindex-cookie:       2010 !default;

// Admin
// --------------------------
$drupal-admin-bar-height: 79px !default;
