.footer-file-link {
    &::before {
        @include sprite("sprite-client-general-download", (
            color: $white
        ));

        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 10px;
        content: "";
    }
}
