.contactperson {
    position: relative;
}

.contactperson__wrapper {
    padding: 20px;
    background-color: $lighter-grey;

    @include media-breakpoint-up(md) {
        padding: 40px;
    }

    .contactperson__content {
        &:has(.contactperson__person:nth-child(2)) {
            padding-top: 2rem;
        }
    }
}

.contactperson__nav {
    position: relative;
    display: flex;
    flex-flow: column;

    &.open {
        .contactperson__nav-arrow {
            transform: rotate(180deg);
        }

        .contactperson__title:not(:last-of-type) {
            margin-bottom: $grid-gutter-width / 2;
        }
    }

    .contactperson__nav-arrow {
        &:nth-child(2) {
            display: none;
        }
    }

    @include media-breakpoint-down(md) {
        // hide non-active when navigation is closed
        &:not(.open) .contactperson__title:not(.active) {
            display: none;
        }
    }

    @include media-breakpoint-up(md) {
        flex-flow: row;
    }

    .contact-border-bottom {
        position: absolute;
        bottom: -1px;
        width: 100%;
        border-bottom: 3px solid $black;

        @include media-breakpoint-up(md) {
            border-bottom-width: 1px;
        }
    }
}

// remove border-bottom when there is no contact person title
.contactperson__title.active:first-child + .contactperson__nav-arrow + .contact-border-bottom {
    border-bottom: 0;
}

.contactperson__nav-arrow {
    @include transition();
    position: absolute;
    top: 1px;
    right: 0;
    width: 25px;
    height: 25px; // same height as min-height of contactperson__title
    pointer-events: none;
    fill: $primary;

    @include media-breakpoint-up(md) {
        display: none;
    }
}

.contactperson__title {
    @include transition();
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    font-size: $h5-font-size;
    line-height: 1;
    cursor: pointer;

    @include media-breakpoint-up(md) {
        &::before {
            @include transition();
            $h: 3px;
            position: absolute;
            right: 0;
            bottom: $h / -2;
            left: 0;
            width: 0;
            height: $h;
            margin: 0 auto;
            content: "";
            background-color: $black;
        }
    }

    &:focus {
        outline: none;
    }

    &.active::before {
        width: 100%;
    }

    @include media-breakpoint-up(md) {
        text-transform: uppercase;

        &:hover,
        &:focus,
        &.active {
            font-weight: $font-weight-bold;
        }

        + .contactperson__title {
            margin-left: 40px;
        }
    }
}

.contactperson__person {
    display: none;

    .contactperson__info {
        display: flex;
        flex-direction: column;
    }

    h3 {
        margin-bottom: 10px;

        @include media-breakpoint-down(sm) {
            margin-top: $grid-gutter-width;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: $grid-gutter-width;
        }

        + span {
            @include media-breakpoint-down(sm) {
                margin-top: $grid-gutter-width;
            }

            @include media-breakpoint-up(md) {
                margin-top: calc(#{$grid-gutter-width} * -1);
                margin-bottom: $grid-gutter-width;
            }
        }
    }

    hr {
        margin: ($grid-gutter-width * 1.5) 0;
    }

    ul {
        padding: 0;
        list-style: none;

        .sprite {
            width: 18px;
            height: 18px;
        }
    }

    li + li {
        margin-top: $grid-gutter-width / 2;
    }

    &.active {
        display: block;
    }
}

.contactperson__icon {
    margin-right: 18px;
    text-align: center;
}

// BAD USE CASE - better is to use if condition on twig to remove the button
.page-node-type-job .contactperson__person hr ~ * {
    display: none;
}

// temporarily hide contactperson navigation on english pages until HEK-181 is resolved
// stylelint-disable-next-line selector-no-qualifying-type
html[lang="en"] {
    // stylelint-disable-next-line selector-no-qualifying-type,selector-max-id
    #paragraph-298 {
        // stylelint-disable-next-line selector-no-qualifying-type,selector-max-id
        .contactperson__nav {
            display: none;
        }
    }
}
