// Position
//
// There are various utility classes for position in bootstrap

.position-absolute-full {
    @include position-absolute-full();
}

.position-fixed-top {
    position: fixed;
    top: 0;
}

.position-fixed-bottom {
    position: fixed;
    bottom: 0;
}


// Font-Weights

.font-weight-medium {
    // stylelint-disable-next-line declaration-no-important
    font-weight: $font-weight-medium !important;
}

.font-weight-semi-bold {
    // stylelint-disable-next-line declaration-no-important
    font-weight: $font-weight-semi-bold !important;
}


// Vertical group

.vertical-group {
    > :last-child {
        margin-bottom: 0;
    }
}
