// VARIABLES
// --------------------------

// Colors
//
// Theme name (key) - background (bg), color of headlines, color of bodytext
// if you need bootstrap elements in your custom color - add it to $customer-theme-colors
// the $customer-theme-colors map is for own styles and to prevent to much iterations of bootstraps $theme-colors
// stylelint-disable-next-line scss/dollar-variable-default
$primary-dark: #76757b; // don't know why this variable is not loaded from _overrides.scss
$lighter-grey: #eee; // don't know why this variable is not loaded from _overrides.scss
$dark-grey: #76757b; // don't know why this variable is not loaded from _overrides.scss
$customer-theme-colors: () !default;
$customer-theme-colors: map-merge(
    (
        "primary": (
            "background": $primary,
            "headline": $black,
            "bodytext": $black,
            "card": $primary-dark $white,
            "highlight-box": "light",
            "keyfact": $secondary $white,
            "ajax-loader": $secondary
        ),
        "secondary": (
            "background": $secondary,
            "headline": $white,
            "bodytext": $white,
            "link": $white,
            "link-hover": $black,
            "card": $primary $black,
            "highlight-box": "light",
            "slider-nav-border-color": $primary
        ),
        "light": (
            "background": $lighter-grey,
            "headline": color-contrast($lighter-grey),
            "bodytext": color-contrast($lighter-grey)
        ),
        "middle": (
            "background": $gray-500,
            "headline": color-contrast($gray-500),
            "bodytext": color-contrast($gray-500)
        ),
        "dark": (
            "background": $dark-grey,
            "headline": color-contrast($dark-grey),
            "bodytext": color-contrast($dark-grey),
            "link": color-contrast($dark-grey),
            "link-hover": $secondary, // link-hover is needed if use link
            "card": $primary $black,
            "keyfact": $primary $white
        ),
    ),
    $customer-theme-colors
);

$custom-colors: (
    "gray-100": $gray-100
);

$theme-colors: map-merge($theme-colors, $custom-colors);
