$transition-collapse-horizontal: width .35s ease, visibility .35s ease;

.sidebar-menu {
    position: fixed;
    top: 230px;
    right: 0;
    z-index: $zindex-fixed;
    flex-direction: column;
    align-items: flex-end;

    .collapse-wrapper {
        display: flex;
        align-items: flex-start;
    }

    .collapse {
        @include transition($transition-collapse-horizontal);
        width: 430px;
        visibility: hidden;

        &.show {
            visibility: visible;
        }
    }

    .collapsing {
        @include transition($transition-collapse-horizontal);
        width: 0;
        height: auto;
    }
}

.sidebar-menu--item {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 74px;
    min-height: 88px;
    padding: 10px;
    margin-bottom: 10px;
    font-size: px-to-rem(12px);
    line-height: 1.2143;
    text-align: center;

    svg {
        width: auto;
        max-width: 90%;
        height: 25px;
        margin: 0 0 10px;
    }

    &:nth-child(2) {
        svg {
            height: 22px;
        }
    }

    @include media-breakpoint-up(lg) {
        max-width: 84px;
        font-size: px-to-rem(14px);

        svg {
            height: 30px;
        }
    }

    @include media-breakpoint-up(xxl) {
        max-width: 104px;
        min-height: 108px;
    }
}

.sidebar-menu--flyout {
    padding: $grid-gutter-width;
    background-color: $gray-300;

    svg {
        width: 18px;
        height: 18px;
        margin-right: 18px;
    }
}
