.slogan {
    font-family: $headings-font-family;
    font-size: px-to-rem(20px);
    font-weight: $font-weight-extra-bold;
    line-height: 1;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
        font-size: px-to-rem(50px);
    }

    &__hekuma {
        display: inline-block;
        width: 100%;
        font-weight: $font-weight-normal;
    }
}
