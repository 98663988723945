// Benefits layout
.paragraph--benefits {
    .list {
        @include make-row(px-to-rem(2px));

        --#{$variable-prefix}gutter-y: #{px-to-rem(2px)};

        > * {
            @include make-col-ready();
        }

        @include row-cols(2);

        @include media-breakpoint-up(lg) {
            @include row-cols(4);
        }

        @include media-breakpoint-up(xl) {
            @include row-cols(5);
        }
    }
}

// Benefits Tiles
.benefit__tile {
    display: flex;
    flex: 0 0 auto;
    flex-flow: column;
    align-items: center;
    height: 100%;
    min-height: 170px;
    padding: 20px 10px 10px;
    line-height: 22px;
    color: $white;
    word-break: break-word;
    background-color: $primary-dark;

    @include media-breakpoint-up(sm) {
        position: relative;
        min-height: 234px;
        padding: 30px 20px 20px;
        line-height: 25px;
    }

    .media {
        width: 80px;
        height: 80px;

        @include media-breakpoint-up(sm) {
            width: 90px;
            height: 90px;
        }

        svg {
            width: 100%;
            height: 100%;
        }

        path,
        circle {
            fill: $white;
        }
    }

    &:focus {
        outline: 0;

        .benefit__description {
            visibility: visible;
            opacity: 1;

            @include media-breakpoint-down(sm) {
                position: fixed;
                z-index: 9000;
            }
        }
    }
}

.benefit__headline {
    margin-top: 20px;
    font-size: 18px;
    color: $white;
    text-align: center;

    @include media-breakpoint-up(md) {
        font-size: 20px;
    }
}

.benefit__description {
    @include transition();
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    //color: $black;
    visibility: hidden;
    background-color: $secondary;
    border: 15px solid $secondary;
    opacity: 0;

    *:last-child {
        margin-bottom: 0;
    }
}

/**
 * this is a css solution
 * to detect devices that have a primary input mechanism to can hover over elements
 * or you use modernizer solution and move the css code from @media (hover: hover) to body.no-touchevents
 */
@media (hover: hover) and (pointer: fine) {
    @include media-breakpoint-up(sm) {
        .benefit__tile {
            position: relative;
            overflow: hidden;

            &:hover {
                .benefit__description {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}
