// Sticky Toolbar
.page-actions {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;

    @include media-breakpoint-up(lg) {
        padding-bottom: 60px;
    }

    .btn-group {
        background-color: $white;
        box-shadow: $box-shadow-sm;

        @include media-breakpoint-up(md) {
            padding: $grid-gutter-width / 4;
            box-shadow: $box-shadow;
        }
    }

    .btn {
        justify-content: center;
        margin: 0;
        text-decoration: none;
        text-transform: initial;

        &:not(.social-share-btn)::before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            display: block;
            width: 1px;
            height: 50%;
            margin: auto;
            content: "";
            background-color: $gray-900;
        }
    }
}

.page-actions__list {
    .btn {
        @include media-breakpoint-up(lg) {
            min-width: 240px;
        }
    }
}
