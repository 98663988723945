// Reboot
//
// Extends and overrides the bootstrap reboot which is forked from Normalize.css

// BODY
//
// Set first relative element to ensure other elements will depend on this

body {
    position: relative;
}

// Address

address {
    margin-bottom: 0;
}


// Lists

ol,
ul {
    padding-left: 0;
}

ol,
ul,
dl {
    margin-bottom: 0;
}

// Nav (reset)
//
// Remove default list styles within navigations
nav {
    ul,
    ol {
        list-style-type: none;
    }
}

// Links
//
// Remove default focus behaviour. Remember to define focus styles!

a {
    &:focus {
        outline: 0;
    }
}


// Strong
//
// Replaces the bootstrap font-weight-bolder to a fixed defined value

b,
strong {
    font-weight: $font-weight-bold;
}


// Button
//
// Remove platform-specific styling

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: none; // stylelint-disable-line property-no-vendor-prefix
}

// Remove bootstraps outline on none styled buttons
button {
    &:focus {
        outline: 0;
    }
}

// Tables
//
// Ensure tables fill up as much space as possible.

table {
    width: 100%;
}


// Video
//
// Remove unnecessary outline.

video {
    outline: 0;
}
