/**
 * only style for node--type-event
 */
.node--type-event {
    .stage--meta-date {
        font-size: $h2-font-size-xs;

        @include media-breakpoint-up(md) {
            @include font-size($h2-font-size);
        }
    }

    .node__content {
        p + .btn {
            margin: ($grid-gutter-width * .5) 0;
        }

        + .node__paragraphs {
            margin-top: -($grid-gutter-width * .5);
        }
    }

    .card {
        &:hover,
        &:focus {
            .card-body {
                background-color: $teaser-hover-grey;
            }
        }
    }

    .card-body {
        @include transition();

        @include media-breakpoint-up(sm) {
            min-height: 230px;
        }

        @include media-breakpoint-up(md) {
            min-height: 270px;
        }
    }

    .hover-fix {
        min-height: 50px; // 1 btn height (single-line btn)
    }

    .card-more,
    .card-text {
        @include transition();
        margin-bottom: 0;
    }

    /**
     * this is a css solution
     * to detect devices that have a primary input mechanism to can hover over elements
     * or you use modernizer solution and move the css code from @media (hover: hover) to body.no-touchevents
     */
    @media (hover: hover) and (pointer: fine) {
        @include media-breakpoint-up(sm) {
            .paragraph--layout-grid &,
            .paragraph--layout-slider & {
                .card {
                    &:not(:hover):not(:focus) .card-more,
                    &:is(:hover, :focus) .card-text {
                        height: 0;
                        overflow: hidden;
                        opacity: 0;
                    }
                }

                .hover-fix {
                    min-height: px-to-rem(100px); // 2 btn height (single-line btn)
                }
            }
        }
    }
}

// if the css solution not the best solution
//body.no-touchevents {}
