.conversational {
    $pxs: 30px;
    $p: 50px;
    $sxs: ($pxs * .5);
    $s: ($p * .5);
    padding: $pxs calc(#{divide(100%, 12)} + #{$grid-gutter-width * .5});
    background-color: $lighter-grey;

    @at-root .path-frontpage & {
        margin-top: -$pxs;
    }

    @include media-breakpoint-up(lg) {
        padding: $p calc(#{divide(100%, 12)} + #{$grid-gutter-width * .5});

        @at-root .path-frontpage & {
            margin-top: -$p;
        }
    }

    h3 {
        margin-bottom: $sxs;
        color: $primary;
        text-transform: none;

        @include media-breakpoint-up(md) {
            margin-bottom: $s;
            font-weight: $font-weight-bold;
        }
    }

    //.breadcrumb {
    //    padding-bottom: 4px;
    //    border-bottom: 2px solid $black;
    //
    //    &:empty {
    //        display: none;
    //    }
    //}

    //.breadcrumb-item {
    //    cursor: pointer;
    //
    //    + .breadcrumb-item::before {
    //        margin-right: px-to-rem(5px);
    //    }
    //}

    //.layer__nav {
    //    //display: flex;
    //    padding-top: $sxs;
    //    margin: 0 ($grid-gutter-width * -.5);
    //
    //    @include media-breakpoint-up(md) {
    //        padding-top: $s;
    //    }
    //
    //    //.btn {
    //    //    margin-right: ($grid-gutter-width * .5);
    //    //    margin-left: ($grid-gutter-width * .5);
    //    //}
    //
    //    .btn-link {
    //        padding-right: 0;
    //        padding-left: 0;
    //    }
    //}

    .dropdown-toggle,
    .dropdown-menu {
        width: 100%;
    }

    .dropdown-toggle {
        padding-left: 0;
        margin-top: -10px;
        margin-bottom: $sxs;
        text-transform: none;
        border-bottom: 2px solid $black;

        @include media-breakpoint-up(md) {
            margin-bottom: $s;
        }

        &::before {
            position: absolute;
            top: 10px;
            right: 0;
            width: 16px;
            height: 10px;
        }
    }

    .dropdown-item {
        text-transform: none;
        cursor: pointer;
    }

    .layer {
        @include make-row();

        > * {
            @include make-col-ready();
        }

        // important to include row-cols after .layer style
        @include row-cols(2);

        &,
        > * {
            --#{$variable-prefix}gutter-y: #{$grid-gutter-width * .25};
            --#{$variable-prefix}gutter-x: #{$grid-gutter-width * .25};
        }

        //@include media-breakpoint-up(sm) {
        //    @include row-cols(2);
        //}

        @include media-breakpoint-up(md) {
            @include row-cols(3);

            &,
            > * {
                --#{$variable-prefix}gutter-y: #{$grid-gutter-width * .5};
                --#{$variable-prefix}gutter-x: #{$grid-gutter-width * .5};
            }
        }

        @include media-breakpoint-up(xl) {
            &,
            > * {
                --#{$variable-prefix}gutter-y: #{$grid-gutter-width};
                --#{$variable-prefix}gutter-x: #{$grid-gutter-width};
            }
        }
    }

    .gbox {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106

        &:not(.active) {
            cursor: pointer;
        }

        &.active,
        &:hover,
        &:focus {
            .title::before {
                width: 100%;
            }
        }

        .title {
            @extend h5;
            position: relative;
            flex: 1 1 auto;
            padding: ($grid-gutter-width * .5) ($grid-gutter-width * .33) ($grid-gutter-width * .33);
            margin: 0;
            color: $white;
            background-color: $primary;

            @include media-breakpoint-up(md) {
                padding-right: ($grid-gutter-width * .66);
                padding-left: ($grid-gutter-width * .66);
            }

            &::before {
                @include transition();

                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                z-index: 1;
                display: block;
                width: 0;
                height: 6px;
                content: "";
                background-color: $secondary;

                @include media-breakpoint-up(md) {
                    height: 10px;
                }
            }
        }

        .gbox__icon:empty + .title {
            padding-top: $grid-gutter-width;
            padding-bottom: ($grid-gutter-width * .83);
        }

        .gbox__icon:not(:empty) + .title::before {
            top: -6px;

            @include media-breakpoint-up(md) {
                top: -10px;
            }
        }
    }
}
//.conversational {
//    .prerendered-entity {
//        width: 100%;
//        height: 100%;
//    }
//
//    .layer {
//        display: flex;
//        flex-flow: wrap;
//    }
//
//    /* paragraphs inside conversational never have a padding */
//    .paragraph {
//        padding-top: 0;
//        padding-bottom: 0;
//    }
//
//    /* we dont want tabs in contact person paragraphs inside conversational form */
//    .paragraph--contact-person {
//        .contactperson__nav {
//            display: none;
//        }
//
//        .contactperson__content {
//            padding-top: 0;
//        }
//
//        .contactperson__person:first-child  {
//            display: block;
//        }
//    }
//
//    .layer__header {
//        margin-bottom: 20px;
//    }
//}

//.path-frontpage .conversational {
//    margin-top: -80px;
//}
