// Header nav
// --------------------------

.header-nav {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    flex-basis: 0;
    flex-direction: column;
    flex-grow: 1; // Necessary for IE11.
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    visibility: hidden;
    // Ensure that header nav not use additional space and force
    // system branding block text to unnecessarily wrap.
    background-color: $white;
    box-shadow: $box-shadow;

    @include transition(visibility .2s, transform .2s);

    &.is-active {
        display: flex;
        height: auto;
        visibility: visible;
    }

    @supports (flex-basis: max-content) {
        flex-basis: max-content;
    }

    @include media-breakpoint-up($primary-nav-breakpoint) {
        position: static;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        max-width: none;
        padding-top: 0;
        padding-bottom: 0;
        overflow: visible;
        visibility: visible;
        border-top: 0;
        box-shadow: none;
        transform: none;

        @include transition(transform .2s);
    }
}

.secondary-nav__wrapper {
    display: flex;

    @include media-breakpoint-up($primary-nav-breakpoint) {
        order: -1;
    }
}
