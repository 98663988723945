@use "sass:math";

// Button (reset)
// --------------------

.btn,
button,
[type="button"],
[type="reset"],
[type="submit"] {

    // make svgs in buttons as height es the font-size
    svg {
        display: inline-block;
        height: $btn-font-size;
        margin-top: math.div(($btn-line-height * $btn-font-size) - $btn-font-size, 2);
        margin-right: 5px;
        vertical-align: top;
        fill: currentColor;
    }
}

// Remove border-radius from .btn-link
.btn-link {
    @include border-radius(0);
}
