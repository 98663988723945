@mixin fake-max-container-width() {
    $extend-breakpoint: true;

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $container-width: if(map-get($container-max-widths, $breakpoint) != null, map-get($container-max-widths, $breakpoint), 0);
            @if ($extend-breakpoint) {
                margin-right: calc(-1 * (((100vw - #{$container-width}) / 2) + #{$container-padding-x}));
                margin-left: calc(-1 * (((100vw - #{$container-width}) / 2) + #{$container-padding-x}));
            } @else {
                margin-right: -$container-padding-x;
                margin-left: -$container-padding-x;
            }

            padding-right: calc(((100vw - #{$container-width}) / 2) + #{$container-padding-x});
            padding-left: calc(((100vw - #{$container-width}) / 2) + #{$container-padding-x});
        }

        // Once the current breakpoint is reached, stop extending
        @if ($breakpoint == breakpoint-prev($primary-nav-breakpoint)) {
            $extend-breakpoint: false;
        }
    }
}
