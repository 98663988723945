.node--page-job {
    .job-info-inner {
        height: 100%;
        padding: 1.25rem;

        &:last-child {
            @include media-breakpoint-down(md) {
                margin-top: 1rem;
            }
        }

        h2 {
            font-size: 1.5rem;
            font-weight: $font-weight-bold;
        }
    }
}
