// Overrides
// --------------------------

// Bootstrap overrides (see ~bootstrap/scss/_variables.scss)
// --------------------------

// Grid
$screen-design-max-width: 1920px; // FullHD
$over-screen-design-max-width: 2160px; // FullHD+
// Bootstrap (remove xxl, screen design use xl on FullHD)
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1192px
);

// Color system

$primary:   #bdc5cf;
$primary-dark: #76757b !default;
$secondary: #c00;

$white:    #fff;
$gray-100: #f7f7f7;
$gray-300: #eee;
$gray-500: #e6e6e6;
$gray-900: #d8d8d8;
$light-grey: $primary;
$lighter-grey: $gray-300;
$teaser-grey: #dee3ea;
$teaser-hover-grey: #d7dbe0;
$dark-grey: $primary-dark;
$black:    #000;

$color-automobile: #00559c;
$color-medicine: #00ab9f;
$color-consumer: #fab900;

// hekuma squares
$hekuma-square-1: #7e7f84;
$hekuma-square-2: #8d9099;
$hekuma-square-3: #9da1aa;
$hekuma-square-4: #acb3bd;
$hekuma-square-5: $secondary;
$hekuma-square-6: #bdc6cf;

$red: $secondary;
$blue: #00596d;
$green: #00aba0;
$orange: #fbba00;
$hekuma-grey: #6d696d;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:   4.5;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark:      $gray-900; // same as body-color
$color-contrast-light:     $white;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:                false;
$enable-rounded:              false;
$enable-shadows:              false;
$enable-gradients:            false;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$grid-gutter-width:           1.5rem;
$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer / 4,
    2: $spacer / 2,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
);

$paragraphs-spacing-y: 32px;
$paragraphs-spacing-y-xl: 64px;


// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white;
$body-color:                $black;


// Links
//
// Style anchor elements.

$link-color:                              $black;
//$link-decoration:                         null;
$link-shade-percentage:                   20%;
$link-hover-color:                        $secondary;
//$link-hover-decoration:                   null;


// MOBILE NAV BUTTON (Hamburger)
$mobile-nav-button-label-offset: 1rem;
$mobile-nav-button-icon-layer-width: 1.5rem;
$mobile-nav-button-icon-layer-height: .1875rem;
$mobile-nav-button-icon-layer-spacing: .4375rem;
$mobile-nav-button-icon-layer-color: $black;
$mobile-nav-button-icon-hover-layer-color: $secondary;
$mobile-nav-button-icon-active-layer-color: $black;
$mobile-nav-button-icon-layer-border-radius: 0;


// Components
//
// Define common padding and border radius sizes and more.

$border-color: $gray-300;


// Fonts
$font-family-base:    var(--font-base);
$font-family-second:  var(--font-second);

$font-size-base-xs:   1rem; // browser default: 16px
$font-size-base:      1rem * 1.125; // ~18px | browser default: 16px

$font-weight-normal:  400;
$font-weight-medium:  500;
$font-weight-semibold:  600;
$font-weight-bold:    700;

$font-weight-base:    $font-weight-normal;

$line-height-base:    1.5;
$h1-line-height:      1.2; // ~60px
$h2-line-height:      1.1905; // ~39px
$h3-line-height:      1.1429; // ~27px
$h4-line-height:      1.1667; // ~35px
$h5-line-height:      1.3333; // ~32px
$h6-line-height:      $line-height-base;

$h1-font-size:                $font-size-base * 2.667; // 48px
$h2-font-size:                $font-size-base * 1.8333; // 33px
$h2-subheadline-font-size:    $font-size-base * 2; // 36px
$h3-font-size:                $font-size-base * 1.167; // 21px
$h4-font-size:                $font-size-base * 1.6667; // 30px
$h5-font-size:                $font-size-base * 1.3333; // 24px
$h6-font-size:                $font-size-base;

$h1-font-size-xs:             $font-size-base-xs * 2; // 32px
$h2-font-size-xs:             $font-size-base-xs * 1.5; // 24px
$h3-font-size-xs:             $font-size-base-xs * 1.25; // 20px
$h4-font-size-xs:             $font-size-base-xs * 1.125; // 18px
$h5-font-size-xs:             $font-size-base-xs;
$h6-font-size-xs:             $font-size-base-xs;

$headings-margin-bottom:      1rem;
$headings-font-family:        $font-family-second;
$headings-font-weight:        $font-weight-bold;
$headings-line-height:        1.2;
$headings-color:              $dark-grey;


// Base theme overrides (see ~Base/scss/_variables.scss)
// --------------------------
// Fonts
$font-name-base: "Arial";
$font-path-base: "";

$font-name-second: "Montserrat";
$font-path-second:  "../fonts/montserrat";

$font-name-lato: "Lato";
$font-path-lato:  "../fonts/lato";


// Site
$site-footer-padding-t: $spacer * 5;
$site-footer-bg-color: $primary-dark;

$site-header-bg: $white;

$sticky-header-height: 130px;
$sticky-header-scrolling-height: 90px;

$primary-nav-link-color: $body-color;
$primary-nav-link-hover-color: $secondary;

$secondary-nav-link-color: $body-color;
$secondary-nav-link-hover-color: $secondary;

$footer-nav-link-color: $white;
$footer-nav-link-hover-color: $secondary;

// drupal paragraph xs md lg
$drupal-region-min-height:  3.125rem 5rem 6.25rem;  // 50px 80px 100px
$drupal-paragraph-spacing:  3.125rem 5rem 6.25rem;  // 50px 80px 100px

// Paragraph
//$paragraph-margin-bottom: $grid-gutter-width / 2;
$paragraph-padding-top: $grid-gutter-width / 2;
$paragraph-padding-bottom: $grid-gutter-width / 2;

// Bootstrap Buttons + Forms
$input-btn-padding-y: .625rem;
$input-btn-padding-x: 1.3125rem;
$input-btn-font-size: 1rem;

// Input
$input-placeholder-color: transparent;
$input-padding-y: .5rem;
$input-padding-x: 1rem;
$input-bg: transparent;
$input-border-color: $black;
$input-border-width: 2px;
$input-focus-box-shadow: 0;

// Checkbox
$form-check-input-border: $input-border-width solid $input-border-color;
$form-check-input-checked-color: $primary;
$form-check-input-checked-bg-color: transparent;
$form-check-input-checked-border-color: $black;
$form-check-input-focus-box-shadow: none;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'><path d='M0 0 h 10 v 10 h -10 Z' fill='#{$form-check-input-checked-color}' /></svg>");
$form-check-input-radio-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>");

// Select
$form-select-bg-position: right center;
$form-select-bg-size: 20px 18px;
$form-select-indicator-color: $primary;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
$form-select-focus-box-shadow: none;

// Form-Validation
$form-feedback-invalid-color: $red;
$form-validation-states: (
    "invalid": (
        "color": $form-feedback-invalid-color,
        "icon": ""
    )
);

// Bootstrap card (teaser)
$card-spacer-y: 1.375rem;
$card-spacer-x: 1.5625rem;
$card-bg: $primary;
$card-color: currentColor;
$card-border-radius: 0;
$card-border-width: 0;

// Bootstrap blockquote (statement)
$blockquote-margin-y: $spacer;
$blockquote-font-size: $font-size-base * 1.3333;
$blockquote-footer-color: $white;
$blockquote-footer-font-size: $font-size-base;

// Slider
//$slider-arrow-width: 60px;
//$slider-arrow-height: 76px;
$swiper-theme-color: $black;
$swiper-theme-bg: $primary;
$swiper-theme-color-hover: $white;
$swiper-theme-bg-hover: $secondary;

// Bootstrap Nav-Tabs
$nav-tabs-border-color: $black;
$nav-tabs-link-hover-border-color: transparent;
$nav-tabs-link-active-color: $black;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-border-color: transparent;

// Bootstrap Accordion
$accordion-bg: transparent;
$accordion-padding-x: 0;
$accordion-border-width: 1px;
$accordion-border-color: $black;
$accordion-border-radius: 0;
$accordion-button-active-color: $black;
$accordion-button-active-bg: transparent;
$accordion-icon-color: $primary;
$accordion-icon-active-color: $primary;

// Bootstrap Dropdowns
$dropdown-font-size: .875rem;
$dropdown-bg: $gray-300;
$dropdown-border-color: $secondary;
$dropdown-border-radius: 0;
$dropdown-border-width: 6px;
$dropdown-box-shadow: null;
$dropdown-icon-color: $black;

$dropdown-link-color: $black;
$dropdown-link-hover-color: $secondary;
$dropdown-link-hover-bg: transparent;

$dropdown-link-active-color: $secondary;
$dropdown-link-active-bg: transparent;

$dropdown-item-padding-y: .3125rem; // 5px
$dropdown-item-padding-x: 1.125rem; // 18px

// Bootstrap Breadcrumb
$breadcrumb-item-padding-x: .625rem; // 10px
$breadcrumb-font-size: .9375rem; // 15px
$breadcrumb-divider-color: $secondary;
$breadcrumb-divider: quote("");

// Bootstrap Table
$table-cell-padding-y: .625rem; // 10px
$table-cell-padding-x: 1.5625rem; // 25px
$table-variants: null;
$table-border-width: 2px;
$table-striped-order: even;
// Custom Table
$table-custom-border-color: $white;
$table-custom-bg: $lighter-grey;
$table-custom-striped-bg: rgba($gray-500, .5);


// Bootstrap Figures
$figure-caption-font-size: .9375rem;
$figure-caption-color: $black;

// Bootstrap Offcanvas
$offcanvas-bg-color: $primary;
