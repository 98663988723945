// Navigation social
// --------------------------

// Should always be the nav object
.social-nav {
    display: flex;

    svg {
        width: 20px;
    }

    .nav-item {
        margin: 0 $grid-gutter-width / 2;
        text-transform: none;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .nav-link {
        padding: 0;
    }
}
