// Fonts

// Montserrat
@include font-face(
    $font-name-second,
    "#{$font-path-second}/montserrat-light-webfont",
    ("woff2", "woff", "ttf")
) {
    font-style: normal;
    font-weight: $font-weight-light;
}

@include font-face(
    $font-name-second,
    "#{$font-path-second}/montserrat-regular-webfont",
    ("woff2", "woff", "ttf")
) {
    font-style: normal;
    font-weight: $font-weight-normal;
}

@include font-face(
    $font-name-second,
    "#{$font-path-second}/montserrat-medium-webfont",
    ("woff2", "woff", "ttf")
) {
    font-style: normal;
    font-weight: $font-weight-medium;
}

@include font-face(
    $font-name-second,
    "#{$font-path-second}/montserrat-semibold-webfont",
    ("woff2", "woff", "ttf")
) {
    font-style: normal;
    font-weight: $font-weight-semibold;
}

@include font-face(
    $font-name-second,
    "#{$font-path-second}/montserrat-bold-webfont",
    ("woff2", "woff", "ttf")
) {
    font-style: normal;
    font-weight: $font-weight-bold;
}

@include font-face(
    $font-name-second,
    "#{$font-path-second}/montserrat-black-webfont",
    ("woff2", "woff", "ttf")
) {
    font-style: normal;
    font-weight: $font-weight-black;
}

@include font-face(
    $font-name-lato,
    "#{$font-path-lato}/lato-v24-latin-regular",
    ("woff2")
) {
    font-style: normal;
    font-weight: $font-weight-normal;
}

@include font-face(
    $font-name-lato,
    "#{$font-path-lato}/lato-v24-latin-italic",
    ("woff2")
) {
    font-style: italic;
    font-weight: $font-weight-normal;
}

@include font-face(
    $font-name-lato,
    "#{$font-path-lato}/lato-v24-latin-700",
    ("woff2")
) {
    font-style: normal;
    font-weight: $font-weight-bold;
}
