$history-border-width: 6px;
$history-knob-size: 18px;
.history::after {
    display: block;
    width: $history-border-width;
    height: 50px;
    margin: 0 auto;
    content: "";
    background: linear-gradient(180deg, transparent 12%, $gray-500 12%, rgba($gray-500, .95) 24%, transparent 24%, transparent 34%, rgba($gray-500, .9) 34%, rgba($gray-500, .75) 44%, transparent 44%, transparent 54%, rgba($gray-500, .5) 54%, rgba($gray-500, .3) 64%, transparent);
}

.history__title span,
.history__date {
    display: inline-block;
    max-width: 90%;
    padding: px-to-rem(8px) px-to-rem(15px);
    font-size: px-to-rem(24px);
    font-weight: 700;
    line-height: 1;
    color: $white;
    background-color: $primary-dark;
}

.history__title {
    text-align: center;

    span {
        min-width: 220px;
        font-size: px-to-rem(30px);
    }
}

.paragraph {
    .history {
        .history__list {
            position: relative;
            padding: 60px 0 0;
            margin: 0;
            list-style: none;
            background-color: transparent;
            background-image: linear-gradient($gray-500 0, $gray-500 1px);
            background-repeat: repeat-y;
            background-position: center top;
            background-size: $history-border-width 100%;

            // fix content floating
            &::after {
                display: table;
                clear: both;
                content: "";
            }
        }
    }
}

.history__block {
    position: relative;
    min-height: 120px;
    padding: 25px;

    &:not(.theme-primary) {
        background-color: $lighter-grey;
    }

    &::before {
        display: none;

        @include media-breakpoint-up(lg) {
            position: absolute;
            top: $grid-gutter-width;
            left: 100%;
            display: block;
            content: "";
            border: $history-knob-size solid $lighter-grey;
            border-color: transparent transparent transparent $lighter-grey;
        }
    }

    .media:not(:last-child) {
        margin-bottom: 20px;
    }

    .h4 {
        color: $primary;
    }
}

.history__panel {
    margin-bottom: 40px;

    @include media-breakpoint-up(lg) {
        float: left;
        width: calc(50% - #{$history-knob-size * 2});
        margin-bottom: 60px;
        clear: left;
    }

    &:first-child {
        margin-top: 0;
    }

    @include media-breakpoint-up(lg) {
        &:nth-child(even) {
            float: right;
            margin-top: 60px;
            clear: right;

            .history__block::before {
                right: 100%;
                left: auto;
                border-color: transparent $lighter-grey transparent transparent;
            }
        }
    }
}

// theme-primary style
@include media-breakpoint-up(lg) {
    .theme-primary {
        .history__block::before {
            border-left-color: $primary;
        }

        .history__panel:nth-child(even) .history__block::before {
            border-right-color: $lighter-grey;
        }
    }
}
