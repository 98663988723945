// Slim Select
// --------------------------
// stylelint-disable selector-max-class

// Variables
$height: auto;
$spacing-m: $form-select-padding-y $form-select-padding-x $form-select-padding-y $form-select-padding-x;
$primary-color: $form-select-focus-border-color;

@import "node_modules/slim-select/src/slim-select/slimselect";

// --------------------------
// 1. replace with own variables
// 2. rename variable
// 3. add own function to be more flexible

.ss-main {
    color: $form-select-color;

    &:focus-within {
        .ss-single-selected:not(.ss-disabled),
        .ss-multi-selected:not(.ss-disabled) {
            border-color: $form-select-focus-border-color;
            outline: 0;

            @if $enable-shadows {
                @include box-shadow($form-select-box-shadow, $form-select-focus-box-shadow);
            } @else {
                // Avoid using mixin so we can pass custom focus shadow properly
                box-shadow: $form-select-focus-box-shadow;
            }
        }
    }

    // Remove outline from select box in FF
    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $form-select-color;
    }

    .ss-single-selected {
        background-image: unset;
        @include border-radius($form-select-border-radius, 0);

        &.ss-disabled {
            color: $form-select-disabled-color;
            background-color: $form-select-disabled-bg;
            border-color: $form-select-disabled-border-color;
        }

        .placeholder {
            line-height: $form-select-line-height;
        }

        &:focus-within {
            border-color: $form-select-focus-border-color;
            outline: 0;

            @if $enable-shadows {
                @include box-shadow($form-select-box-shadow, $form-select-focus-box-shadow);
            } @else {
                // Avoid using mixin so we can pass custom focus shadow properly
                box-shadow: $form-select-focus-box-shadow;
            }
        }
    }

    .ss-multi-selected {
        height: $height;
        padding: $spacing-m;
        background-image: unset;
        @include border-radius($form-select-border-radius, 0);

        .ss-add {
            align-items: center;
            justify-content: center;
            margin: 0;
        }

        .ss-values {
            height: auto;
            line-height: $form-select-line-height;

            .ss-disabled {
                padding: 0;
                margin: 0;
                line-height: $form-select-line-height;
            }

            .ss-value {
                margin-top: 0;
                line-height: $form-select-line-height;
                color: $form-select-color;
                @include border-radius($form-select-border-radius, 0);
            }
        }

        &.ss-disabled {
            color: $form-select-disabled-color;
            background-color: $form-select-disabled-bg;
            border-color: $form-select-disabled-border-color;

            .ss-values {
                .ss-value {
                    color: $form-select-color;
                }
            }

            &:hover {
                color: $form-select-disabled-color;
            }
        }

        &:focus-within {
            border-color: $form-select-focus-border-color;
            outline: 0;

            @if $enable-shadows {
                @include box-shadow($form-select-box-shadow, $form-select-focus-box-shadow);
            } @else {
                // Avoid using mixin so we can pass custom focus shadow properly
                box-shadow: $form-select-focus-box-shadow;
            }
        }
    }
}

.ss-option {
    &.ss-disabled {
        .ss-option-selected {
            &:hover {
                color: $form-select-disabled-color;
            }
        }
    }

    &:not(.ss-disabled):hover {
        color: $form-select-color;
    }
}
