// general
.primary-nav__sub-toggle {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
}

// mobile
// Search Icon on Header-Menu
.mobile-buttons > button {
    display: flex;
    align-items: center;
    align-self: center;
    min-height: 30px;

    > svg {
        height: 19px;
    }

    + button {
        margin-left: 5px;
    }
}

// meta nav
.meta-nav {
    .nav {
        align-items: center;
    }

    .nav-item--contact {
        .nav-link {
            padding: 7px;
            background-color: $primary;
        }
    }
}

// Lang-Navigation
.block--language {
    display: flex;
    align-items: center;
    text-transform: uppercase;
}

/**
 * Hamburger Flyout-Menu
 */
@include media-breakpoint-down($primary-nav-breakpoint) {
    // header-nav
    .header-nav {
        background-color: $gray-300;
        border-bottom: 10px solid $secondary;

        // set same negative value for .sticky-header height
        .sticky-header & {
            max-height: calc(100vh - 80px);

            @include media-breakpoint-up(md) {
                max-height: calc(100vh - 100px);
            }
        }

        // set same negative value for .sticky-header-scrolling height
        .sticky-header-scrolling & {
            max-height: calc(100vh - 50px);
        }
    }

    // primary-nav & meta-nav
    .primary-nav,
    .meta-nav {
        .nav-item,
        .nav-link {
            width: 100%;
        }

        .nav-item {
            position: relative;
            display: flex;
            flex-direction: column;
        }

        .nav-link {
            &[href]::before {
                @include transition();
                @include sprite("sprite-client-general-chevron-down", (
                    color: $black
                ));
                position: relative;
                top: -3px;
                display: inline-block;
                width: 9px;
                height: 5px;
                margin-right: 5px;
                content: "";
                transform: rotate(-90deg);
            }

            &:hover,
            &.is-active {
                color: $secondary;

                &::before {
                    @include sprite("sprite-client-general-chevron-down", (
                        color: $secondary
                    ));
                }
            }
        }
    }

    // mainnavigation
    .primary-nav {
        .nolink {
            margin-bottom: 0;
            font-weight: $font-weight-bold;
        }

        .nav-link--parent {
            display: none;
        }

        .primary-nav__menu-link--nolink {
            font-size: 20px;
            cursor: default;

            &:hover {
                color: $black;
            }

            .primary-nav__menu-link-inner {
                &:focus,
                &:hover {
                    color: $black;
                }
            }
        }

        // styled level 1
        .level-1 {
            color: $black;
            background-color: $primary;

            &[href]::before {
                @include sprite("sprite-client-general-chevron-down", (
                    color: $black
                ));
            }

            &:hover,
            &:hover + .nav-link,
            &[aria-expanded="true"],
            &.is-active {
                color: $secondary;

                &[href]::before {
                    @include sprite("sprite-client-general-chevron-down", (
                        color: $secondary
                    ));
                }
            }

            &[aria-expanded="true"] {
                color: $white;
                background-color: $primary-dark;

                &[href]::before {
                    @include sprite("sprite-client-general-chevron-down", (
                        color: $white
                    ));
                }

                &::before {
                    transform: rotate(0deg);
                }
            }
        }

        .level-2 {
            font-weight: 600;
        }

        // styled level 4
        .nav-link.level-4 {
            margin-left: 18px; // same as desktop
        }

        .primary-nav__sub-container-2 {
            padding-block: 1rem;
        }

        .primary-nav__sub-container-3 {
            margin-left: 1.5rem;
        }
    }

    // secondary-nav__wrapp
    .secondary-nav__wrapper {
        flex-direction: column;
    }

    // metanavigation
    .meta-nav {
        margin-top: 15px;
    }

    // Lang-Navigation
    .block--language {
        padding: 10px 0;
        margin: 15px 15px 0;
        border-top: 1px solid $gray-900;

        &,
        .dropdown-menu {
            font-size: px-to-rem(18px);
        }

        .language-link {
            color: $secondary;
        }

        .dropdown-toggle {
            display: inline-flex;

            &::before {
                display: none;
            }
        }

        .dropdown-menu {
            position: relative;
            display: inline-flex;
            border-top: none;

            .nav-item::before {
                position: relative;
                top: 1px;
                display: inline-block;
                width: 2px;
                height: 14px;
                margin: 0 8px;
                content: "";
                background-color: $black;
            }
        }

        .nav-item {
            padding: 0;
        }
    }
}

/**
 * Desktop Navigation (with Flyout)
 */
@include media-breakpoint-up($primary-nav-breakpoint) {
    .header-nav {
        justify-content: space-between;
    }

    .primary-nav__menu--level-1 > .nav-item > .nav-link {
        text-transform: uppercase;
    }

    .primary-nav__sub-toggle {
        display: none;
    }

    // secondary nav wrapper
    .secondary-nav__wrapper {
        .nav-item {
            font-size: px-to-rem(14px);
        }

        .nav-link {
            padding: 0 10px;
        }

        .dropdown-menu {
            min-width: 50px;
        }
    }

    .nav + .nolink {
        margin-top: 20px;
    }

    .meta-nav {
        .nav-item {
            text-transform: uppercase;
        }

        .nav-link {
            &:hover {
                color: $secondary;

                &::before {
                    @include sprite("sprite-client-general-chevron-down", (
                        color: $secondary
                    ));
                }
            }

            &.is-active {
                color: $primary-dark;

                &::before {
                    @include sprite("sprite-client-general-chevron-down", (
                        color: $primary-dark
                    ));
                }
            }
        }
    }

    .primary-nav {
        // styled all levels
        .nav-link--parent,
        .nav-link {
            padding: 0;
        }

        // stylelint-disable-next-line selector-no-qualifying-type
        span.nav-link.level-2 {
            &:hover {
                color: $black;
            }
        }

        .primary-nav__menu-link--nolink {
            cursor: default;

            &:hover {
                color: $black;
            }

            .primary-nav__menu-link-inner {
                &:focus,
                &:hover {
                    color: $black;
                }
            }
        }

        // styled level 1
        .nav-link.level-1 {
            margin: 11px 15px 6px;
            overflow: hidden;
            font-size: px-to-rem(16px);
            font-weight: $font-weight-bold;
            cursor: pointer;
            transform: translateY(12px);
            @include transition();

            &:hover {
                color: $primary-dark;

                span {
                    &::after {
                        left: 0;
                    }
                }
            }

            span {
                &::after {
                    position: absolute;
                    bottom: 0;
                    left: -100%;
                    display: inline-block;
                    width: 100%;
                    height: 3px;
                    content: "";
                    background-color: $secondary;
                    @include transition();
                }
            }

            .sticky-header-scrolling & {
                transform: translateY(9px);
            }
        }

        .nav-link.level-3 {
            font-size: 14px;
        }

        .primary-nav__menu-item--active-trail .nav-link.level-1 {
            color: $black;

            span {
                &::after {
                    left: 0;
                }
            }

            .sticky-header-scrolling & { // stylelint-disable-line selector-max-class
                border-bottom-width: 4px;
            }
        }

        // styled level 2
        .nav-link.level-2 {
            margin-bottom: 8px;
        }

        // styled level 4
        .nav-link.level-4 {
            margin: 5px 0 5px 18px;
            font-size: px-to-rem(14px);
            font-weight: $font-weight-light;
            line-height: 1;

            &[href]::before {
                display: none;
            }
        }

        .primary-nav__sub-container-4 {
            // stylelint-disable selector-max-compound-selectors
            .nav[class*="--level-4"] {
                flex-direction: column;

                li {
                    margin-bottom: 0;
                    line-height: 1;

                    + li {
                        margin-top: 2px;
                    }
                }
            }
            // stylelint-enable selector-max-compound-selectors
        }
    }
}
.primary-nav__sub-container {
    font-family: $font-family-second;
}
