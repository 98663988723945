// Icons
// --------------------------

// stylelint-disable selector-no-qualifying-type
img[src$=".svg"] {
    &.svg-image-icon {
        width: 100%;
    }
}
// stylelint-enable selector-no-qualifying-type
