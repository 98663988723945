// overwrite from neo / base
.btn,
button,
[type="button"],
[type="reset"],
[type="submit"] {
    svg {
        margin-right: 10px;
    }
}

.btn {
    display: inline-flex;
    text-align: left;
    text-transform: uppercase;

    @include media-breakpoint-down(md) {
        @include button-size(.625rem, .8125rem, 15px, $btn-border-radius);
        margin-top: (($btn-line-height * 15px) - 15px) / 2;

        > svg {
            width: 15px;
            height: 15px;
        }
    }

    > svg {
        display: flex;
        flex: 0 0 auto;
        width: 18px;
    }

    > span {
        display: flex;
    }
}

.btn-primary {
    @include change-btn-color($white, $white);

    &:focus {
        color: $white;
    }
}

.btn-outline-primary,
.form-submit {
    color: $dark-grey;
    background-color: transparent;
    border-color: $secondary;

    &:hover {
        color: $white;
        background-color: $secondary;
        border-color: $secondary;
    }
}

.btn-outline-secondary {
    color: $primary-dark;
}

.btn-outline-white {
    @include button-outline-variant($white, $primary-dark);
}

.btn-outline-black {
    @include button-outline-variant($black, $white);
}

.btn-micro {
    @include button-size(px-to-rem(5px), px-to-rem(5px), px-to-rem(12px), 0);
    line-height: 1;
}
