.paragraph {
    scroll-margin-top: 70px;

    @include paragraph-whitespace(px-to-rem($paragraphs-spacing-y), $paragraph-margin-bottom);

    @include media-breakpoint-up(md) {
        @include paragraph-whitespace(px-to-rem($paragraphs-spacing-y-xl), ($paragraph-margin-bottom * 2));
    }

    @include media-breakpoint-up(xl) {
        scroll-margin-top: 100px;
    }

    // remove last child margin-bottom
    .paragraph__content-container > *:last-child {
        margin-bottom: 0;
    }

    // fixing accordion and tab content paragraph spacing
    .accordion-body,
    .tab-pane {
        > *:first-child {
            margin-top: 0;
            margin-bottom: 0;

            + .paragraph {
                margin-top: $grid-gutter-width * .5;
                margin-bottom: $grid-gutter-width * .5;

                @include media-breakpoint-up(md) {
                    margin-top: $grid-gutter-width;
                    margin-bottom: $grid-gutter-width;
                }
            }
        }
    }

    p {
        // change link style inside text
        a {
            text-decoration-color: $secondary;
        }
    }
}

.node__content {
    margin-top: $grid-gutter-width;
    margin-bottom: px-to-rem($paragraphs-spacing-y);

    @include media-breakpoint-up(md) {
        margin-bottom: px-to-rem($paragraphs-spacing-y-xl);
    }
}

.paragraph--layout-highlighted {
    .paragraph__content-container {
        height: 100%;
    }

    &,
    &--left .row > div:first-child,
    &--right .row > div:last-child {
        .paragraph__content-container {
            padding: $grid-gutter-width / 2;
            color: $body-color;
            background-color: $primary;
            border-bottom: px-to-rem(3px) solid $secondary;

            @include media-breakpoint-up(sm) {
                padding: px-to-rem(25px) px-to-rem(30px);
            }

            // stylelint-disable selector-max-compound-selectors
            > *:last-child {
                margin-bottom: 0;
            }

            h1,
            .h1,
            h2,
            .h2,
            h3,
            .h3,
            h4,
            .h4,
            h5,
            .h5,
            h6,
            .h6 {
                &:not(p):not(.card-title) {
                    color: currentColor;
                }
            }

            a:not(.btn) {
                color: currentColor;

                &:hover,
                &:focus {
                    color: $secondary;
                }
            }
            // stylelint-enable selector-max-compound-selectors
        }
    }
}

// if highlighted text with media
.paragraph--text-with-media.paragraph--layout-highlighted--right {
    .col--media + .col--text {
        margin: 0;
    }

    .row {
        --#{$variable-prefix}gutter-x: 0;
        --#{$variable-prefix}gutter-y: 0;

        > div .paragraph__content-container {
            height: 100%;
            margin: 0;
            background-color: $primary;
        }
    }
}

.page--entity-bundle-job {
    .paragraph__content-container {
        > ul {
            // have to style spacing like this because
            // there is no inherent spacing between paragraphs "your profile" and "benefits"
            + h2 {
                // stylelint-disable-next-line declaration-no-important
                margin-top: 2rem !important;

                @include media-breakpoint-up(lg) {
                    // stylelint-disable-next-line declaration-no-important
                    margin-top: 4rem !important;
                }
            }
        }
    }
}
