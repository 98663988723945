.accordion-button {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: $h5-font-size;

    &:hover,
    &:focus,
    &:not(.collapsed) {
        font-weight: $font-weight-bold;
    }

    &:not(.collapsed) {
        border-bottom: 3px solid $accordion-border-color;
    }
}

.accordion-item {
    border-width: 1px 0;

    &:first-of-type {
        border-top: 0;
    }
}

.accordion-collapse {
    border: 0;
}

.accordion-body {
    position: relative;
}
