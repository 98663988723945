.keyfacts--bg {
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;

    /* align-items: flex-end;
    min-height: 120px;

    @include media-breakpoint-up(lg) {
        min-height: 190px;
    }

    figure {
        position: absolute;
        right: 8%;
        bottom: 25px;
        z-index: -1;
        width: 100%;
        max-width: 57%;
        max-height: 120px;

        @include media-breakpoint-up(md) {
            right: 15%;
            bottom: 30px;
            max-width: 55%;
            max-height: 175px;
        }
    } */

    img {
        max-height: 100%;
    }
}

.keyfacts--image {
    width: 80%;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 2px solid $hekuma-square-3;
}

.keyfacts--content {
    font-size: px-to-rem(20px);
    font-weight: $font-weight-bold;
    color: $hekuma-square-3;
}

.keyfacts--value {
    display: inline-block; // removed whitespace from markup
    font-size: px-to-rem(50px);
    line-height: 1;

    @include media-breakpoint-up(lg) {
        font-size: px-to-rem(70px);
    }
}

.keyfacts--desc {
    max-width: 90%;
    font-size: px-to-rem(14px);
    line-height: 1.3571; // ~ 19px
    color: $black;
    text-transform: uppercase;
    word-break: break-word;
}
