.primary-nav__sub-container-2 {
    @include fake-max-container-width();

    position: relative;
    z-index: 1;
    background-color: $gray-300;
    border-bottom: 5px solid $secondary;

    > .nav.primary-nav__menu {
        margin-bottom: .5rem;

        &[data-nolink] {
            column-count: unset;

            // stylelint-disable-next-line selector-max-class
            .primary-nav__sub-container-3 > .nav {
                margin-left: 0;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        > li {
            margin-bottom: .5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @include media-breakpoint-up($primary-nav-breakpoint) {
        @include transition();

        position: absolute;
        top: 100%;
        left: $grid-gutter-width / 2;
        z-index: 5; // Appear above search container.
        flex: 0 1 100%;
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;
        overflow: hidden;
        overflow-y: auto;
        visibility: hidden;

        &.is-active {
            padding-top: 40px;
            padding-bottom: 40px;
            visibility: visible;
        }

        > .nav[class*="--level-2"],
        [data-nolink] .nav[class*="--level-3"] {
            display: block;
            column-count: 3;
            column-gap: $grid-gutter-width;
            orphans: 1;
            widows: 1;

            > li {
                display: inline-block;
                width: 100%;
                margin-bottom: .25rem;
            }
        }

        > .nav[class*="--level-2"] {
            .primary-nav__sub-container-3 {
                .nav {
                    display: block;
                    margin-left: 1rem;
                }
            }
        }

        .nav-link,
        .primary-nav__sub-container-3 > .nav-link {
            &[href]::before {
                @include transition();
                @include sprite("sprite-client-general-chevron-down", (
                    color: $black
                ));
                position: relative;
                top: -3px;
                display: inline-block;
                width: 9px;
                height: 5px;
                margin-right: 5px;
                content: "";
                transform: rotate(-90deg);
            }

            &[href]:hover,
            &.is-active {
                color: $secondary;

                &::before {
                    @include sprite("sprite-client-general-chevron-down", (
                        color: $secondary
                    ));
                }
            }
        }

        > .nav-link.nav-link--parent {
            display: inline-block;
            margin-bottom: 30px;
            font-size: px-to-rem(30px);
            font-weight: $font-weight-bold;
            line-height: 1.1667;
            color: $secondary;
            text-transform: uppercase;

            &::before {
                @include sprite("sprite-client-general-chevron-down", (
                    color: $secondary
                ));
                top: -6px;
            }
        }

        // set same negative value for .sticky-header height + &.is-active padding
        .sticky-header & {
            max-height: calc(100vh - #{$sticky-header-height + 80px});
        }

        // set same negative value for .sticky-header-scrolling height + &.is-active padding
        .sticky-header-scrolling & {
            max-height: calc(100vh - #{$sticky-header-scrolling-height + 80px});
        }
    }

    // stylelint-disable selector-max-class
    .primary-nav__sub-container {
        .primary-nav__sub-container-4 {
            .nav.primary-nav__menu {
                margin-left: 0;
                column-count: unset;
            }
        }
    }
    // stylelint-enable selector-max-class
}
