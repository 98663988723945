// Page
// --------------------------
// Layout container
// --------------------------
// First element in Page-level wrapping all elements.
@if is-number($page-wrapper-width) == false {
    @error "`#{$page-wrapper-width}` needs to be a number.";
}

.page--front .region--hero {
    margin-bottom: $paragraph-margin-bottom;

    @include media-breakpoint-up(md) {
        margin-bottom: $paragraph-margin-bottom * 2;
    }
}

.page-wrapper {
    max-width: $page-wrapper-width;
    padding-right: $page-wrapper-padding-r;
    padding-left: $page-wrapper-padding-l;
    margin-right: auto;
    margin-left: auto;
    background-color: $page-wrapper-bg-color;
}

.sticky-header {
    @include transition(padding-top .2s ease-in-out);

    padding-top: 80px;

    .site-header {
        @include transition(height .2s ease-in-out);

        position: fixed;
        top: 0;
        left: 0;
        z-index: $zindex-sticky-header;
        width: 100%;
        height: 80px;
    }

    @include media-breakpoint-up(md) {
        padding-top: 100px;

        .site-header {
            height: 100px;
        }
    }

    @include media-breakpoint-up($primary-nav-breakpoint) {
        padding-top: $sticky-header-height;

        .site-header {
            height: $sticky-header-height;
        }
    }
}

.sticky-header-scrolling {
    padding-top: $sticky-header-scrolling-height;
}

.site-header {
    display: flex;
    align-items: center;
    background-color: $site-header-bg;
    box-shadow: $box-shadow;

    a {
        color: $body-color;

        &:hover {
            color: $secondary;
        }
    }

    &__inner-container {
        display: flex;
    }

    .sticky-header-scrolling & {
        height: 50px;

        @include media-breakpoint-up($primary-nav-breakpoint) {
            height: $sticky-header-scrolling-height;
        }
    }
}

.site-logo {
    display: flex;
    align-items: center;
    width: 130px;
    height: 100%;

    img {
        width: 100%;
    }

    @include media-breakpoint-up(md) {
        width: 150px;
    }

    .sticky-header-scrolling & {
        width: 100px;
    }

    @include media-breakpoint-up($primary-nav-breakpoint) {
        width: 210px;

        .sticky-header-scrolling & {
            width: 150px;
        }
    }
}

hr {
    height: 2px;
    color: $secondary;
    opacity: 1;
}

.container-fluid {
    @include media-breakpoint-up(xxl) {
        width: 86%;
    }
}
