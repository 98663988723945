/**
 * only style for node--type-event
 */
.node--type-job {
    .card {
        &:hover,
        &:focus {
            .card-body {
                background-color: $gray-500;
            }
        }

        &-title {
            color: $dark-grey;
            @include transition();
        }
    }

    .card-subtitle {
        @extend .h5;
    }

    .btn-outline-primary {
        @include button-outline-variant($secondary, $white);

        color: $dark-grey;
    }
}
