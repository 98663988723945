// Mixins for Utilities
// --------------------------

// Expand element complete to parent
@mixin position-absolute-full() {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
