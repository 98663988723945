figure {
    position: relative;
    margin: 0;

    // move figure caption to right position (insert bottom)
    .ratio + .figure-caption {
        position: absolute;
        margin-top: 1px; // fix that 1px to height
        transform: translateY(-100%);
    }

    /* stylelint-disable */
    &.media {
        .contextual button {
            clip: auto !important;
        }
    }
    .stage &.media .contextual {
        right: auto;
        left: 16px;

        button {
            position: relative !important;
            margin: inherit !important;
        }
    }
    /* stylelint-enable */
}

.figure-caption {
    min-height: 60px;
    font-weight: $font-weight-bold;
    line-height: 1.3333;
    background-color: rgba($gray-300, .9);
    border: 10px solid transparent;
    border-width: 10px 15px;

    > *:last-child {
        margin-bottom: 0;
    }

    //@supports (display: -webkit-box) and (-webkit-box-orient: vertical) {
    //    display: -webkit-box;
    //    overflow: hidden;
    //    -webkit-line-clamp: 4;
    //    -webkit-box-orient: vertical;
    //
    //    @include media-breakpoint-up(sm) {
    //        -webkit-line-clamp: 3;
    //    }
    //
    //    @include media-breakpoint-up(md) {
    //        -webkit-line-clamp: 2;
    //    }
    //}
}
