.paragraph--file {
    @include media-breakpoint-down(md) {
        .card {
            cursor: pointer;

            &:focus .card-more {
                visibility: visible;
                opacity: 1;
            }

            .card-more {
                @include transition();
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 9000;
                flex-direction: column;
                overflow: hidden;
                visibility: hidden;
                background-color: $white;
                border: $grid-gutter-width / 2 solid $white;
                opacity: 0;
            }
        }

        .card-body {
            &:hover,
            &:focus {
                background-color: $gray-500;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .card-more > p {
            display: none;
        }
    }
}
