/**
 * use origin code from node_module simplebar.css
 * and implement the modification directly
 * for saving code lines and we don't need to override css code
 *
 * change code for supporting stylelint and scss
 */
// variable for simplebar
$simplebar-opacity: null; // default: 0
$simplebar-opacity-hover: null; // default: .5

$track-height-horizontal: 10px; // default: 11px
$track-height-vertical: $track-height-horizontal; // default: 11px

$bar-pos-top: 0; // default: 2px
$bar-height: 10px; // default: 7px
$bar-min-width: 40px; // default: 10px
$bar-color: $black; // default: black
$bar-border-radius: 0; // default: 7px
$bar-spacing: 0; // default: 2px;
$bar-spacing-horizontal: $bar-spacing; // default: 2px;
$bar-spacing-vertical: $bar-spacing; // default: 2px;

// customize
$track-padding-horizontal: 2px 0;
$track-padding-vertical: 0 2px;
$track-bg-horizontal: #cfcfcf;
$track-bg-vertical: null;
$track-min-width-horizontal: 300px;
$track-max-width-horizontal: 550px;


// helper
%pseudo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

%track-bg-pseudo {
    &::after {
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        display: block;
        width: 100%;
        height: 100%;
        content: "";
    }
}


// SIMPLEBAR
// stylelint-disable declaration-no-important
[data-simplebar] {
    position: relative;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
}

.simplebar-wrapper {
    width: inherit;
    max-width: inherit;
    height: inherit;
    max-height: inherit;
    overflow: hidden;
}

.simplebar-mask {
    @extend %pseudo;
    z-index: 0;
    width: auto !important;
    height: auto !important;
    padding: 0;
    margin: 0;
    overflow: hidden;
    direction: inherit;
}

.simplebar-offset {
    @extend %pseudo;
    box-sizing: inherit !important;
    padding: 0;
    margin: 0;
    direction: inherit !important;
    resize: none !important;
    -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
    position: relative;
    box-sizing: border-box !important;
    display: block;
    width: auto;
    max-width: 100%; /* Not required for horizontal scroll to trigger */
    height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
    max-height: 100%; /* Needed for vertical scroll to trigger */
    direction: inherit;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.simplebar-content {
    &::before,
    &::after {
        display: table;
        content: " ";
    }
}

.simplebar-placeholder {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
    position: relative;
    z-index: -1;
    box-sizing: inherit !important;
    flex-basis: 0;
    flex-shrink: 0;
    flex-grow: inherit;
    float: left;
    width: 100%;
    max-width: 1px;
    height: 100%;
    max-height: 1px;
    padding: 0;
    margin: 0;
    overflow: hidden;
    pointer-events: none;
}

.simplebar-height-auto-observer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    box-sizing: inherit;
    display: block;
    width: 1000%;
    min-width: 1px;
    height: 1000%;
    min-height: 1px;
    overflow: hidden;
    pointer-events: none;
    opacity: 0;
}

.simplebar-track {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    overflow: hidden;
    pointer-events: none;
}

[data-simplebar].simplebar-dragging .simplebar-content {
    pointer-events: none;
    user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
    pointer-events: all;
}

.simplebar-scrollbar {
    position: absolute;
    right: 0;
    left: 0;
    min-height: $bar-min-width;

    &::before {
        position: absolute;
        right: 2px;
        left: 2px;
        content: "";
        background: $bar-color;
        border-radius: $bar-border-radius;
        @if $simplebar-opacity { // customize
            opacity: $simplebar-opacity;
            transition: opacity .2s linear;
        }
    }

    @if $simplebar-opacity-hover { // customize
        &.simplebar-visible::before {
            /* When hovered, remove all transitions from drag handle */
            opacity: .5;
            transition: opacity 0s linear;
        }
    }
}

.simplebar-track.simplebar-vertical {
    top: 0;
    width: $track-height-vertical;

    /* customice ----| */

    @if $track-padding-vertical {
        padding: $track-padding-vertical;
    }

    @if $track-bg-vertical {
        @extend %track-bg-pseudo;

        &::after {
            background-color: $track-bg-vertical;
        }
    }

    /* |---- customice */

    .simplebar-scrollbar::before {
        top: $bar-spacing-vertical;
        bottom: $bar-spacing-vertical;
    }
}

.simplebar-track.simplebar-horizontal {
    left: 0;
    height: $track-height-horizontal;

    /* customice ----| */

    @if $track-padding-horizontal {
        padding: $track-padding-horizontal;
    }

    @if $track-max-width-horizontal {
        max-width: $track-max-width-horizontal;
        margin: 0 auto;

        @include media-breakpoint-up(sm) {
            min-width: $track-min-width-horizontal;
        }
    }

    @if $track-bg-horizontal {
        @extend %track-bg-pseudo;

        &::after {
            background-color: $track-bg-horizontal;
        }
    }

    /* |---- customice */

    .simplebar-scrollbar {
        top: $bar-pos-top;
        right: auto;
        left: 0;
        width: auto;
        min-width: $bar-min-width;
        height: $bar-height;
        min-height: 0;

        &::before {
            right: $bar-spacing-horizontal;
            left: $bar-spacing-horizontal;
            height: 100%;
        }
    }
}

/* Rtl support */
[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
    right: auto;
    left: 0;
}

.hs-dummy-scrollbar-size {
    position: fixed;
    width: 500px;
    height: 500px;
    overflow-x: scroll;
    overflow-y: hidden;
    direction: rtl;
    visibility: hidden;
    opacity: 0;
}

.simplebar-hide-scrollbar {
    position: fixed;
    left: 0;
    overflow-y: scroll;
    visibility: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
// stylelint-enable declaration-no-important

/**
 * adding special style for not working data-attribute data-simplebar-auto-hide="false"
 */
[data-simplebar-auto-hide="false"] {
    .simplebar-track {
        visibility: visible !important; // stylelint-disable-line declaration-no-important
    }
}

[data-simplebar-auto-hide="vertical"] {
    .simplebar-track.simplebar-vertical {
        visibility: visible !important; // stylelint-disable-line declaration-no-important
    }
}

[data-simplebar-auto-hide="horizontal"] {
    .simplebar-track.simplebar-horizontal {
        visibility: visible !important; // stylelint-disable-line declaration-no-important
    }
}
