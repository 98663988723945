/**
 * only style for node--type-location
 */
.node--type-location {
    @extend %node--type-page;

    .card-title,
    .card-text {
        @extend h5;
    }

    .card-text {
        margin-bottom: 0;

        + * {
            margin-top: $paragraph-margin-bottom;
        }
    }
}
