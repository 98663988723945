// TODO remove this file after base-system is updated and hat this pull-request https://bitbucket.org/mosaiq-gmbh/drupal.theme-neo/pull-requests/4/feature-styleable-select-fields
.form-select--custom { // stylelint-disable-line no-duplicate-selectors
    position: relative;

    .dropdown-toggle {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        width: 100%;
        height: 100%;
        opacity: 0;

        @media (hover: hover) and (pointer: fine) {
            display: block;
        }
    }

    .dropdown-menu {
        width: 100%;
        max-height: 25vh;
        overflow: hidden;
        overflow-y: auto;
    }
}

// custom client style
.form-select--custom { // stylelint-disable-line no-duplicate-selectors
}
