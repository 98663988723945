// This styling is needed for arranging form-items evenly in an input-group.

.input-group > .form-item {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}

.form-managed-file {
    position: relative;
}
