.paragraph.ajax--paragraph-loading {
    position: relative;
}

.ajax-progress--throbber {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-bottom: $grid-gutter-width;

    @include media-breakpoint-up(md) {
        margin-bottom: $grid-gutter-width * 2;
    }

    &.ajax-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        align-items: center;
        background-color: rgba($black, .35);
    }

    .throbber {
        width: 50px;
        height: 50px;
        background: transparent;
        border-width: 5px;
    }
}

.ajax-progress--throbber .throbber,
.ajax-progress--fullscreen::before {
    --ajax-throbber-border-color: #{$primary};
    border-color: var(--ajax-throbber-border-color, $black);
    border-style: solid;
    border-right-color: transparent;
    border-radius: 0;
    animation: square-throbber 1.8s linear infinite;
}

@keyframes square-throbber {
    0% {
        border-color: transparent;
        transform: rotate(0deg);
    }
    40% {
        border-color: var(--ajax-throbber-border-color, $black);
    }
    60% {
        border-color: var(--ajax-throbber-border-color, $black);
    }
    100% {
        border-color: transparent;
        transform: rotate(180deg);
    }
}
