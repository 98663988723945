// Mobile nav button
// --------------------------
.mobile-nav-button {
    position: relative;
    display: flex;
    align-items: center;
    align-self: center;
    cursor: pointer;
    background: transparent;
    border: 0;

    &__label {
        position: absolute;
        display: block;
        width: 1px;
        height: 1px;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
        word-wrap: normal;

        @include media-breakpoint-up(sm) {
            position: static;
            width: auto;
            height: auto;
            margin-right: $mobile-nav-button-label-offset;
            overflow: visible;
            clip: auto;
        }
    }

    &__icon {
        @include border-radius($mobile-nav-button-icon-layer-border-radius);
        @include transition($mobile-nav-button-icon-transition);

        position: relative;
        display: block;
        width: $mobile-nav-button-icon-layer-width;
        height: $mobile-nav-button-icon-layer-height;
        margin: $mobile-nav-button-icon-layer-spacing 0;
        background-color: $mobile-nav-button-icon-layer-color;

        &::before,
        &::after {
            @include border-radius($mobile-nav-button-icon-layer-border-radius);
            @include transition($mobile-nav-button-icon-transition);

            position: absolute;
            left: 0;
            width: 100%;
            height: $mobile-nav-button-icon-layer-height;
            content: "";
            background-color: $mobile-nav-button-icon-layer-color;
        }

        &::before {
            top: -$mobile-nav-button-icon-layer-spacing;
        }

        &::after {
            bottom: -$mobile-nav-button-icon-layer-spacing;
        }
    }

    &:hover {
        .mobile-nav-button__icon {
            background-color: $mobile-nav-button-icon-hover-layer-color;

            &::before,
            &::after {
                background-color: $mobile-nav-button-icon-hover-layer-color;
            }
        }
    }

    &[aria-expanded="true"] {
        .mobile-nav-button__icon {
            background-color: transparent;

            &::before {
                top: 0;
                background-color: $mobile-nav-button-icon-active-layer-color;
                transform: rotate(-45deg);
            }

            &::after {
                bottom: 0;
                background-color: $mobile-nav-button-icon-active-layer-color;
                transform: rotate(45deg);
            }
        }
    }

    &[aria-expanded="true"]:hover {
        .mobile-nav-button__icon {
            &::before,
            &::after {
                background-color: $mobile-nav-button-icon-hover-layer-color;
            }
        }
    }
}

