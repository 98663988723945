// Mobile buttons
// --------------------------

.mobile-buttons {
    display: flex;

    @include media-breakpoint-up($primary-nav-breakpoint) {
        display: none;
    }
}
